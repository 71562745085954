import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { confirmUserName, updateWithUserName, } from '../../../redux/slices/auth';
import * as Images from "../../../utilities/images";
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const CreateUserNameModal = (props) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [userName, setUserName] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const [errorBlurMessage, setErrorBlurMessage] = useState("");


    const isValidUsername = (username) => {
        // Regular expression for username validation
        const userNameRegex = /^[a-zA-Z0-9]+$/;
        return userNameRegex.test(username);
    };

    const handleInputBlur = () => {
        /*   if (!userName) {
              toast.error(t("Please enter userName"))
              // setErrorMessage({ email: t("Please enter userName") });
              return;
          }
          else if (!isValidUsername(userName)) {
              toast.error(t("Please enter valid userName"))
              // setErrorMessage({ email: t("Please enter valid userName") });
              return;
          }
          let params = {
              username: userName
          }
          dispatch(confirmUserName({
              ...params, cb(res) {
                  if (res?.status == 400) {
                      toast.error(t(res.data.message))
                      // setErrorBlurMessage({ email: t(res.data.message) });
                  }
                  if (res?.status == 200) {
                      setErrorBlurMessage("")
                  }
              }
          })) */


    };


    //Create for update username.
    const hadleUserNameClick = () => {
        if (!userName) {
            toast.error(t("Please enter userName"))
            // setErrorMessage({ email: t("Please enter userName") });
            return;
        }
        else if (!isValidUsername(userName)) {
            toast.error(t("Please enter valid userName"))
            // setErrorMessage({ email: t("Please enter valid userName") });
            return;
        }
        let params = {
            username: userName,
        }
        dispatch(updateWithUserName({
            ...params, cb(res) {
                if (res?.status == 400) {
                    toast.error(t(res.data.message))
                    // setErrorMessage({ error: t(res.data.message) });
                }
                if (res?.status == 200) {
                    navigate("/dashboard")
                    setErrorMessage("")
                    props.onCloseModal()
                }
            }
        }))
    }


    return (
        <>
            <div className='row'>
                <div className='col-md-12 mb-3'>
                    <div className="form-group">
                        <div className='position-relative mb-2'>
                            <label htmlFor="username" className="form-label">{t("Username")}</label>
                            <input type="email" id="username" className="form-input" placeholder={t("EnterUsername")}
                                value={userName}
                                onChange={(e) => { setUserName(e.target.value); setErrorMessage(""); setErrorBlurMessage("") }}
                                onBlur={() => handleInputBlur()}
                            />
                        </div>
                        {errorMessage ?
                            <span className={errorMessage?.email || errorMessage?.error ? 'validationMsg' : "d-none"}>{errorMessage?.email ? errorMessage?.email : errorMessage?.error}</span>
                            : ""}
                        {errorBlurMessage ?
                            <span className={errorBlurMessage?.email ? 'validationMsg' : "d-none"}>{errorBlurMessage?.email}</span>
                            : ""}
                    </div>
                </div>
                <button className='loginBtnCommon btnYellow mw-100 my-2' type='button' disabled={errorMessage?.email || errorBlurMessage?.email} onClick={() => hadleUserNameClick()} >{t("Continue")} </button>
            </div>
        </>
    )
}

export default CreateUserNameModal