import React, { useEffect, useState } from 'react';
import * as Images from "../../../utilities/images";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { generateUserName, signUpWithEmail, userNameLimit, signUpWithUserName, confirmEmail, sendOtp, signUpWithPhon, confirmUserName, confirmPhoneNo, userSocialLoginSignup, setGenerateUserName } from '../../../redux/slices/auth';
import { toast } from 'react-toastify';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import { useGoogleLogin } from '@react-oauth/google';
import TelegramLoginButton from 'react-telegram-login';
import { useTranslation } from 'react-i18next';
import LoginModal from './loginModal';
import { useAuthSelector } from '../../../redux/selector/auth';
import OTPModal from './OTPModal';

const SignupModal = (props) => {
    const dispatch = useDispatch();
    const authSelector = useAuthSelector();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [type, setType] = useState(props?.type);
    const [activeTab, setActiveTab] = useState(1);
    const [userNameLimitData, setUserNameLimitData] = useState();
    const [show, setShow] = useState(false);
    const [emailAgree, setEmailAgree] = useState(false);
    const [userAgree, setUserAgree] = useState(false);
    const [phonAgree, setPhonAgree] = useState(false);
    const [socialAgree, setSocialAgree] = useState(false);
    const [phoneNo, setPhoneNo] = useState("");
    const [phoneCode, SetPhoneCode] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [errorBlurMessage, setErrorBlurMessage] = useState("");
    const [formData, setFormData] = useState({ email: "", password: "", conformPassword: "" });
    const [userData, setUserData] = useState({ userName: "", password: "", conformPassword: "" });
    const [errorUsername, setErrorusername] = useState({ userName: "", password: "", conformPassword: "" });
    const [generateLoading, setGenerateLoading] = useState(false);
    const [emailOtpLoading, setEmailOtpLoading] = useState(false);
    const [phoneLoading, setPhoneLoading] = useState(false);
    const [usernameLoading, setUsernameLoading] = useState(false);
    const [isOtpModal, setIsOtpModal] = useState(false);
    const [otpModalData, setOtpModalData] = useState({});
    const [phoneVal, setphoneVal] = useState("");

    //Create for change email or password.
    const handleChange = (e) => {
        const { value, name } = e.target;
        setFormData({ ...formData, [name]: value.trim() });
        setErrorMessage("")
        if (name == "email") {
            setErrorBlurMessage("");
        }
    }


    const handleTypeChange = (flag) => {
        if (flag == "forgetModal") {
            props?.handleModalChange("forgetModal")
        } else {
            setType(flag)
        }
    }

    //Create for change username or password.
    const handleUserChange = (e) => {
        const { value, name } = e.target;
        setUserData({ ...userData, [name]: value.trim() });
        setErrorusername({ ...errorUsername, [name]: "" });
        if (name == "userName") {
            setErrorBlurMessage("");
        }
    }


    //Create for change tabs.
    const handleTabClick = async (tabNumber) => {
        setActiveTab(tabNumber);
        setErrorMessage("");
        setErrorBlurMessage("");
    };

    const isValidUsername = (username) => {
        // Regular expression for username validation
        const userNameRegex = /^[a-zA-Z0-9]+$/;
        return userNameRegex.test(username);
    };


    const getUserName = () => {
        if (!show) {
            toast.error(t("Please check confirm box"))
            // setErrorMessage({ agree: t("Please check confirm box") });
            return;
        }
        let params = {}
        setGenerateLoading(true)
        dispatch(generateUserName({
            ...params, cb(res) {
                if (res) {
                    let params = {
                        username: res?.data?.data?.username,
                        password: res?.data?.data?.password,
                        type: "2"
                    }
                    dispatch(signUpWithUserName({
                        ...params, cb(res) {
                            if (res) {
                                navigate("/dashboard", { state: params })
                                setGenerateLoading(false)
                            }
                            else {
                                setGenerateLoading(false)
                            }
                        }
                    }))
                } else {
                    setGenerateLoading(false);
                }
            }
        }))
    }

    const getUserNameLimit = () => {
        let params = {
        }
        dispatch(userNameLimit({
            ...params, cb(res) {
                setUserNameLimitData(res.data.data)
            }
        }))
    }

    const hadleEmailOtpClick = () => {
        if (!formData.email) {
            toast.error(t("Please enter email"))
            // setErrorMessage({ email: t("Please enter email") });
            return;
        }
        else if (!formData.password) {
            toast.error(t("Please enter password"))
            // setErrorMessage({ password: t("Please enter password") });
            return;
        }
        else if (formData.email == formData.password) {
            toast.error(t("Email and password can not be same"))
            // setErrorMessage({ password: t("Email and password can not be same") });
            return;
        }
        else if (formData.password != formData.conformPassword) {
            toast.error(t("ConformPassword should be match"))
            // setErrorMessage({ confirm: t("ConformPassword should be match") });
            return;
        }
        else if (!emailAgree) {
            toast.error(t("Please check confirm box"))
            // setErrorMessage({ agree: t("Please check confirm box") });
            return;
        }
        let params = {
            email: formData.email.trim(),
            password: formData.password,
            is_mail: true
        }
        setEmailOtpLoading(true)
        dispatch(signUpWithEmail({
            ...params, cb(res) {
                setEmailOtpLoading(false)
                if (res?.status == 400) {
                    toast.error(t(res.data.message))
                    // setErrorMessage({ otperror: t(res.data.message) });
                }
                if (res?.status == 200) {
                    setOtpModalData({
                        email: formData.email.trim(),
                        password: formData.password,
                        type: 'emailSignup',
                    })
                    setIsOtpModal(true)
                    setErrorMessage("")
                }

            }
        }))

    }

    //Create for send otp to phone number.
    const handlePhoneClick = () => {
        if (!phoneNo) {
            toast.error(t("Please enter phone number"))
            // setErrorMessage({ email: t("Please enter phone number") });
            return;
        }
        else if (!phonAgree) {
            toast.error(t("Please check confirm box"))
            // setErrorMessage({ agree: t("Please check confirm box") });
            return;
        }
        let params = {
            phone_code: phoneCode.includes('+') ? phoneCode : '+' + phoneCode,
            phone: phoneNo,
            type: "signup",
        }
        setPhoneLoading(true)
        dispatch(sendOtp({
            ...params, cb(res) {
                setPhoneLoading(false)
                if (res.status) {
                    if (res?.status == 400) {
                        toast.error(t(res.data.message))
                        // setErrorMessage({ error: t(res.data.message) });
                    }
                    if (res?.status == 200) {
                        setOtpModalData({
                            phone_code: phoneCode.includes('+') ? phoneCode : '+' + phoneCode,
                            phone: phoneNo,
                            type: 'phoneSignup',
                        })
                        setIsOtpModal(true)
                    }

                }
            }
        }))
    }


    //Create for signup using username.
    const hadleUserNameClick = () => {
        if (!userData.userName) {
            toast.error(t("Please enter userName"))
            // setErrorusername((prevData) => ({ ...prevData, userName: t("Please enter userName") }));
            return;
        }
        else if (!isValidUsername(userData.userName)) {
            toast.error(t("Please enter valid username"))
            // setErrorusername((prevData) => ({ ...prevData, userName: t("Please enter valid username") }));
            return;
        }
        else if (!userData.password) {
            toast.error(t("Please enter password"))
            // setErrorusername((prevData) => ({ ...prevData, password: t("Please enter password") }));
            return;
        }

        else if (userData.password != userData.conformPassword) {
            toast.error(t("ConformPassword should be match"))
            // setErrorusername((prevData) => ({ ...prevData, conformPassword: t("ConformPassword should be match") }));
            return;
        }
        else if (!userAgree) {
            toast.error(t("Please check confirm box"))
            // setErrorMessage({ agree: t("Please check confirm box") });
            return;
        }

        let params = {
            username: userData.userName,
            password: userData.password,
            type: "1"
        }
        setUsernameLoading(true)
        dispatch(signUpWithUserName({
            ...params, cb(res) {
                setUsernameLoading(false)
                if (res?.status == 400) {
                    toast.error(t(res.data.message))
                    // setErrorMessage({ error: t(res.data.message) });
                }
                if (res?.status == 200) {
                    // navigate("/dashboard")
                    dispatch(setGenerateUserName({ ...authSelector?.generatedUserInfo, saved: true }))
                    props?.handleModalChange("userNameModal", userData.userName, null)
                    setErrorMessage("")
                }
            }
        }))
    }


    //create for check email,, username or phone number is available or not.
    const handleInputBlur = (flag) => {
        /*  if (flag == "email") {
             if (formData.email) {
                 let params = { email: formData.email }
                 dispatch(confirmEmail({
                     ...params, cb(res) {
                         if (res?.status == 400) {
                             toast.error(t(res.data.message))
                             // setErrorBlurMessage({ email: t(res.data.message) });
                         }
                         if (res?.status == 200) {
                             setErrorBlurMessage("")
                         }
                     }
                 }))
             }
         }
         else if (flag == "username") {
             if (userData.userName) {
                 let params = {
                     username: userData.userName
                 }
                 dispatch(confirmUserName({
                     ...params, cb(res) {
                         if (res?.status == 400) {
                             toast.error(t(res.data.message))
                             // setErrorBlurMessage({ email: t(res.data.message) });
                         }
                         if (res?.status == 200) {
                             setErrorBlurMessage("")
                         }
                     }
                 }))
             }
         }
         else if (flag == "phone") {
             if (phoneNo) {
                 let params = {
                     phone_code: phoneCode.includes('+') ? phoneCode : '+' + phoneCode,
                     phone: phoneNo,
                 }
                 dispatch(confirmPhoneNo({
                     ...params, cb(res) {
                         if (res?.status == 400) {
                             toast.error(t(res.data.message))
                             // setErrorBlurMessage({ email: t(res.data.message) });
                         }
                         if (res?.status == 200) {
                             setErrorBlurMessage("")
                         }
                     }
                 }))
             }
         } */
    };


    //Create for input phon number or phone code.
    const onChangePhoneNumber = (value, data, event, formattedValue) => {
        console.log(data)
        let phoneCode = data.dialCode;
        let phoneNumber = value.slice(data.dialCode?.length);
        setPhoneNo(phoneNumber);
        SetPhoneCode(phoneCode);
        setphoneVal(value)
        setErrorMessage("");
        setErrorBlurMessage("");
    }


    //Create for signup with google.
    const signUpWithGoogle = useGoogleLogin({
        onSuccess: async (codeResponse) => {
            if (!codeResponse) {
                toast.error(t("User details not found"))
                return;
            }
            let params = {
                token: codeResponse.access_token,
                provider: "google",
            }
            dispatch(userSocialLoginSignup({
                ...params, cb(res) {
                    if (res?.status == 400) {
                        // setErrorMessage({ error: t(res.data.message) });
                        toast.error(t(res.data.message))
                    }
                    if (res?.status == 200) {
                        if (res.data.data.user.username !== null) {
                            dispatch(setGenerateUserName({ ...authSelector?.generatedUserInfo, saved: true }))
                        }
                        navigate("/dashboard")
                        setErrorMessage("")
                    }
                }
            }))
        },
        onError: (error) => console.log('Login Failed:', error)
    })

    const handleGoogleLogin = () => {
        if (socialAgree) {
            signUpWithGoogle();
        } else {
            // Handle the case where the condition is not satisfied
            // setErrorMessage({ agree: t("Please check confirm box") });
            toast.error(t("Please check confirm box"))
        }
    };


    const handleTelegramResponse = (response) => {
        // console.log("Telegram Auth Response", response);
        if (!response) {
            toast.error(t("User details not found"))
            return;
        }
        let params = {
            token: response.hash,
            provider: "telegram",
            username: response.id
        }
        dispatch(userSocialLoginSignup({
            ...params, cb(res) {
                if (res?.status == 400) {
                    // setErrorMessage({ error: t(res.data.message) });
                    toast.error(t(res.data.message))
                }
                if (res?.status == 200) {
                    if (res.data.data.user.username !== null) {
                        dispatch(setGenerateUserName({ ...authSelector?.generatedUserInfo, saved: true }))
                    }
                    navigate("/dashboard")
                    setErrorMessage("")
                }
            }
        }))
    };


    const signUpWithTelegram = () => {
        toast.error(t("Please check confirm box"))
        // setErrorMessage({ agree: t("Please check confirm box") });
    }


    useEffect(() => {
        getUserNameLimit()
    }, []);

    return (
        <>
            {type == "signupModal" ? isOtpModal ?
                <OTPModal setIsOtpModal={setIsOtpModal} data={otpModalData} /> :
                <div className="signup-modal">
                    <h2 className="heading22">{t('signup')}</h2>
                    <div className='scroll__'>
                        <div className="tab-buttons">
                            <button
                                className={activeTab === 1 ? 'tabBtn active' : 'tabBtn'}
                                onClick={() => handleTabClick(1)}
                            >
                                <img src={Images.oneClickIcon} alt="icon" className='tabIcons' />
                                <span className='heading'>{t('With One Click')}</span>
                                <span className='mobileheading d-none'>{t(`with`)}<br></br>{t(`oneClick`)}</span>
                            </button>
                            <button
                                className={activeTab === 2 ? 'tabBtn active' : 'tabBtn'}
                                onClick={() => handleTabClick(2)}
                            >
                                <img src={Images.emailIcon} alt="icon" className='tabIcons' />

                                <span className='heading'>{t('With Email')}</span>
                                <span className='mobileheading d-none'>{t(`with`)}<br></br>{t(`Email`)}</span>
                            </button>
                            <button
                                className={activeTab === 3 ? 'tabBtn active' : 'tabBtn'}
                                onClick={() => handleTabClick(3)}
                            >
                                <img src={Images.Whatsapp} alt="icon" className='tabIcons' />

                                <span className='heading'>{t('With Whatsapp')} </span>
                                <span className='mobileheading d-none'>{t(`with`)}<br></br>{t(`Whatsapp`)}</span>
                            </button>
                            <button
                                className={activeTab === 4 ? 'tabBtn active' : 'tabBtn'}
                                onClick={() => handleTabClick(4)}
                            >
                                <img src={Images.socialIcon} alt="icon" className='tabIcons' />
                                <span className='heading'>{t('With Social Media')}</span>
                                <span className='mobileheading d-none'>{t(`with`)}<br></br>{t(`socialMedia`)}</span>

                            </button>
                            <button
                                className={activeTab === 5 ? 'tabBtn active' : 'tabBtn'}
                                onClick={() => handleTabClick(5)}
                            >
                                <img src={Images.userIcon} alt="icon" className='tabIcons' />
                                <span className='heading'> {t('With Username')}</span>
                                <span className='mobileheading d-none'>{t(`with`)}<br></br>{t(`Username`)}</span>
                            </button>
                        </div>
                        <div className='hr'></div>

                        <div className="tab-content">
                            {activeTab === 1 &&
                                <div>
                                    <div className='col-md-12 mb-2'>
                                        <div className='customCheckbox'>
                                            <div className="form-group">
                                                <input
                                                    type="checkbox"
                                                    id="checkbox"
                                                    checked={show}
                                                    onChange={() => { setShow(!show); setErrorMessage("") }}
                                                />
                                                <label htmlFor="checkbox" className='subText12'>{t('I confirm that I am of legal age and agree with site rules')}</label>

                                                {errorMessage ?
                                                    <span className={errorMessage?.agree ? 'validationMsg mt-3' : "d-none"}>{errorMessage?.agree ? <>{t(`${errorMessage?.agree}`)}</> : ""}</span>
                                                    : ""}
                                                <div className='hr my-3 mb-2'></div>
                                            </div>
                                        </div>

                                    </div>


                                    <p className='subTxtPurpl12'>{t("WelcomeMessage", { min: userNameLimitData?.limit_min_username, max: userNameLimitData?.limit_max_username, })} </p>
                                    <div className='text-center'>
                                        <button disabled={generateLoading} className='loginBtnCommon btnYellow my-3' onClick={() => getUserName()}>
                                            {generateLoading ? <><span className="spinner-border spinner-border-sm"></span></>
                                                : <> {t("Generate")}</>}
                                        </button>
                                    </div>
                                    <div className='col-md-12 text-center '>
                                        <p className='loginTxt'>{t("AlreadyHaveAccount")}  <Link href="#"
                                            onClick={() => setType("loginModal")}
                                            className='loginLink'>{t("Login")}</Link></p>
                                    </div>
                                    <form className="customForm">
                                        <div className='row'>
                                        </div>
                                    </form>
                                </div>
                            }
                            {activeTab === 2 &&
                                <div>
                                    <form className="customForm">
                                        <div className='row'>
                                            <div className='col-md-12 mb-4'>
                                                <div className="form-group">
                                                    <label htmlFor="username" className="form-label">{t("EmailAddress")}</label>
                                                    <input type="email" id="username" className="form-input" placeholder={t("EnterEmail")} value={formData.email} name="email"
                                                        onChange={(e) => handleChange(e)}
                                                        onBlur={() => handleInputBlur("email")}
                                                    />
                                                    {errorMessage ?
                                                        <span className={errorMessage?.email ? 'validationMsg' : "d-none"}>{errorMessage?.email ? <>{t(`${errorMessage?.email}`)}</> : ""}</span>
                                                        : ""}
                                                    {errorBlurMessage ?
                                                        <span className={errorBlurMessage?.email ? 'validationMsg' : "d-none"}>{errorBlurMessage?.email}</span>
                                                        : ""}
                                                </div>

                                            </div>
                                            <div className='col-md-6 mb-4'>
                                                <div className="form-group">
                                                    <label htmlFor="password" className="form-label">{t("CreatePassword")}</label>
                                                    <input type="text" id="password" className="form-input" placeholder={t("EnterPassword")} value={formData.password} name="password" onChange={(e) => handleChange(e)} />
                                                    {errorMessage ?
                                                        <span className={errorMessage?.password ? 'validationMsg' : "d-none"}>{errorMessage?.password ? <>{t(`${errorMessage?.password}`)}</> : ""}</span>
                                                        : ""}
                                                </div>
                                            </div>
                                            <div className='col-md-6 mb-4'>
                                                <div className="form-group">
                                                    <label htmlFor="password" className="form-label">{t("ConfirmPassword")}</label>
                                                    <input type="text" id="password" className="form-input" placeholder={t("EnterConfirmPassword")} value={formData.conformPassword} name="conformPassword" onChange={(e) => handleChange(e)} />
                                                    {errorMessage ?
                                                        <span className={errorMessage?.confirm ? 'validationMsg' : "d-none"}>{errorMessage?.confirm ? <>{t(`${errorMessage?.confirm}`)}</> : ""}</span>
                                                        : ""}
                                                </div>
                                            </div>
                                            <div className='col-md-12 mb-3'>
                                                <div className='customCheckbox'>
                                                    <div className="form-group">
                                                        <input type="checkbox" id="checkbox" checked={emailAgree} onChange={() => { setEmailAgree(!emailAgree); setErrorMessage("") }} />
                                                        <label htmlFor="checkbox" className='subText12'>{t("I confirm that I am of legal age and agree with site rules")}</label>

                                                    </div>
                                                    {errorMessage ?
                                                        <span className={errorMessage?.agree ? 'validationMsg' : "d-none"}>{errorMessage?.agree ? <>{t(`${errorMessage?.agree}`)}</> : ""}</span>
                                                        : ""}
                                                    {errorMessage ?
                                                        <span className={errorMessage?.otperror ? 'validationMsg' : "d-none"}>{errorMessage?.otperror}</span>
                                                        : ""}
                                                </div>
                                            </div>

                                            <div className='col-md-12 text-center mt-2'>
                                                <button className='loginBtnCommon btnYellow' type='button' disabled={errorMessage?.email || errorBlurMessage?.email}
                                                    onClick={() => hadleEmailOtpClick()} >
                                                    {emailOtpLoading ? <><span className="spinner-border spinner-border-sm"></span></>
                                                        : <> {t("VerifyEmail")} </>}
                                                </button>
                                            </div>
                                            <div className='col-md-12 text-center mt-3'>
                                                <p className='loginTxt'>{t("AlreadyHaveAccount")} <Link href="#"
                                                    onClick={() => setType("loginModal")}
                                                    className='loginLink'>{t("Login")}</Link></p>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            }
                            {activeTab === 3 &&
                                <div>
                                    <form className="customForm">
                                        <div className='row'>
                                            <div className='col-md-12 mb-4'>
                                                <div className="form-group">
                                                    <label htmlFor="username" className="form-label">{t("Phone Number")}</label>
                                                    <PhoneInput
                                                        className="form-input"
                                                        country={props.countryName || 'us'}
                                                        enableSearch={true}
                                                        value={phoneVal}
                                                        placeholder={t("EnterPhoneNumber")}
                                                        onChange={(value, data, event, formattedValue) => { onChangePhoneNumber(value, data, event, formattedValue) }}
                                                        onBlur={() => handleInputBlur("phone")}
                                                    />
                                                    {errorMessage ?
                                                        <span className={errorMessage?.email ? 'validationMsg' : "d-none"}>{errorMessage?.email ? <>{t(`${errorMessage?.email}`)}</> : ""}</span>
                                                        : ""}
                                                    {errorBlurMessage ?
                                                        <span className={errorBlurMessage?.email ? 'validationMsg' : "d-none"}>{errorBlurMessage?.email}</span>
                                                        : ""}
                                                </div>
                                            </div>

                                            <div className='col-md-12 mb-4'>
                                                <div className='customCheckbox'>
                                                    <div className="form-group">
                                                        <input type="checkbox" id="checkbox" checked={phonAgree} onChange={() => { setPhonAgree(!phonAgree); setErrorMessage("") }} />
                                                        <label htmlFor="checkbox" className='subText12'>{t("I confirm that I am of legal age and agree with site rules")}</label>
                                                    </div>
                                                    {errorMessage ?
                                                        <span className={errorMessage?.agree ? 'validationMsg' : "d-none"}>{errorMessage?.agree ? <>{t(`${errorMessage?.agree}`)}</> : ""}</span>
                                                        : ""}
                                                    {errorMessage ?
                                                        <span className={errorMessage?.error ? 'validationMsg' : "d-none"}>{t(errorMessage?.error)}</span>
                                                        : ""}
                                                </div>
                                            </div>
                                            <div className='col-md-12 text-center'>
                                                <button className='loginBtnCommon btnYellow' type='button' disabled={errorMessage?.email || errorBlurMessage?.email} onClick={() => handlePhoneClick()}>
                                                    {phoneLoading ? <><span className="spinner-border spinner-border-sm"></span></>
                                                        : <> {t("SignUp")} </>}
                                                </button>
                                            </div>

                                            <div className='col-md-12 text-center mt-3'>
                                                <p className='loginTxt'>{t("AlreadyHaveAccount")}<Link href="#"
                                                    onClick={() => setType("loginModal")}
                                                    className='loginLink'>{t("Login")}</Link></p>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            }
                            {activeTab === 4 &&
                                <div>
                                    <form className="customForm">
                                        <div className='row'>
                                            <div className='col-md-12 mb-4'>
                                                <div className='customCheckbox'>
                                                    <div className="form-group">
                                                        <input type="checkbox" id="html" checked={socialAgree} onChange={() => { setSocialAgree(!socialAgree); setErrorMessage("") }} />
                                                        <label htmlFor="html" className='subText12'>{t("I confirm that I am of legal age and agree with site rules")}</label>
                                                    </div>
                                                    {errorMessage ?
                                                        <span className={errorMessage?.agree ? 'validationMsg' : "d-none"}>{errorMessage?.agree ? <>{t(`${errorMessage?.agree}`)}</> : ""}</span>
                                                        : ""}
                                                </div>
                                            </div>
                                            <div className='col-md-6 text-center'>
                                                <button className='googleBtn' type='button'
                                                    onClick={() => handleGoogleLogin()}
                                                ><img src={Images.googleIcon} alt="google" className='me-2' /> {t("SignUpWithGoogle")}</button>
                                                {errorMessage ?
                                                    <span className={errorMessage?.error ? 'validationMsg' : "d-none"}>{t(errorMessage?.error)}</span>
                                                    : ""}
                                            </div>


                                            <div className='col-md-6 text-center telegrambtn'>
                                                <button className='googleBtn facebook' type='button'
                                                    onClick={signUpWithTelegram}
                                                ><img src={Images.TelegramIcon} alt="facebook" className='me-2 telegramIcon' />  {t("SignUpWithTelegram")}
                                                </button>
                                                {socialAgree ?
                                                    <div className='innertelegram_button '>
                                                        <TelegramLoginButton
                                                            botName="itechbetting_bot"
                                                            dataOnauth={handleTelegramResponse}
                                                        />
                                                    </div>
                                                    : ""}

                                            </div>
                                            <div className='col-md-12 text-center mt-3'>
                                                <p className='loginTxt'>{t("AlreadyHaveAccount")} <Link href="#"
                                                    onClick={() => setType("loginModal")}
                                                    className='loginLink'>{t("Login")}</Link></p>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            }
                            {activeTab === 5 &&
                                <div>
                                    <form className="customForm">
                                        <div className='row'>
                                            <div className='col-md-12 mb-4'>
                                                <div className="form-group">
                                                    <label htmlFor="username" className="form-label">{t("Username")}</label>
                                                    <input type="text" id="username" className="form-input" placeholder={t("EnterUsername")} value={userData.userName} name="userName"
                                                        onChange={(e) => handleUserChange(e)}
                                                        onBlur={() => handleInputBlur("username")}
                                                    />
                                                    {errorUsername ?
                                                        <span className={errorUsername?.userName ? 'validationMsg' : "d-none"}>{errorUsername?.userName ? <>{t(`${errorUsername?.userName}`)}</> : ""}</span>
                                                        : ""}
                                                    {errorBlurMessage ?
                                                        <span className={errorBlurMessage?.email ? 'validationMsg' : "d-none"}>{errorBlurMessage?.email}</span>
                                                        : ""}
                                                </div>
                                            </div>
                                            <div className='col-md-6 mb-4'>
                                                <div className="form-group">
                                                    <label htmlFor="password" className="form-label">{t("CreatePassword")}</label>
                                                    <input type="text" id="password" className="form-input" placeholder={t("EnterPassword")} value={userData.password} name="password" onChange={(e) => handleUserChange(e)} />
                                                    {errorUsername ?
                                                        <span className={errorUsername?.password ? 'validationMsg' : "d-none"}>{errorUsername?.password ? <>{t(`${errorUsername?.password}`)}</> : ""}</span>
                                                        : ""}
                                                </div>
                                            </div>
                                            <div className='col-md-6 mb-4'>
                                                <div className="form-group">
                                                    <label htmlFor="password" className="form-label">{t("ConfirmPassword")}</label>
                                                    <input type="text" id="password" className="form-input" placeholder={t("EnterConfirmPassword")} value={userData.conformPassword} name="conformPassword" onChange={(e) => handleUserChange(e)} />
                                                    {errorUsername ?
                                                        <span className={errorUsername?.conformPassword ? 'validationMsg' : "d-none"}>{errorUsername?.conformPassword ? <>{t(`${errorUsername?.conformPassword}`)}</> : ""}</span>
                                                        : ""}
                                                </div>
                                            </div>
                                            <div className='col-md-12 mb-3'>
                                                <div className='customCheckbox'>
                                                    <div className="form-group">
                                                        <input type="checkbox" id="checkbox" checked={userAgree} onChange={() => { setUserAgree(!userAgree); setErrorMessage("") }} />
                                                        <label htmlFor="checkbox" className='subText12'>{t("I confirm that I am of legal age and agree with site rules")}</label>
                                                    </div>
                                                    {errorMessage ?
                                                        <span className={errorMessage?.agree ? 'validationMsg' : "d-none"}>{errorMessage?.agree ? <>{t(`${errorMessage?.agree}`)}</> : ""}</span>
                                                        : ""}
                                                    {errorMessage ?
                                                        <span className={errorMessage?.error ? 'validationMsg' : "d-none"}>{t(errorMessage?.error)}</span>
                                                        : ""}
                                                </div>
                                            </div>
                                            <div className='col-md-12 text-center mt-2'>
                                                <button className='loginBtnCommon btnYellow' type='button' disabled={errorMessage?.email || errorBlurMessage?.email} onClick={() => hadleUserNameClick()} >
                                                    {usernameLoading ? <><span className="spinner-border spinner-border-sm"></span></>
                                                        : <> {t("SignUp")} </>}
                                                </button>
                                            </div>
                                            <div className='col-md-12 text-center mt-3'>
                                                <p className='loginTxt'>{t("AlreadyHaveAccount")}  <Link href="#"
                                                    onClick={() => setType("loginModal")}
                                                    className='loginLink'>{t("Login")}</Link></p>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                :
                <>
                    <LoginModal handleTypeChange={(flag) => handleTypeChange(flag)} countryName={props.countryName} />
                </>
            }
        </>
    );
};

export default SignupModal;