import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import 'react-phone-input-2/lib/style.css';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import OtpTimer from "otp-timer";
import { sendOtp, setGenerateUserName, signUpWithEmail, signUpWithPhon, userLogin } from '../../../redux/slices/auth';
import { useTranslation } from 'react-i18next';
import { useAuthSelector } from '../../../redux/selector/auth';
import * as Images from "../../../utilities/images";

const OTPModal = (props) => {
    const dispatch = useDispatch();
    const authSelector = useAuthSelector();
    const navigate = useNavigate();
    const { data } = props

    const { t } = useTranslation();
    const [otp, setOtp] = useState('');
    const [key, setKey] = useState(Math.random());
    const [timeRemaining, setTimeRemaining] = useState(); // 10 minutes in seconds
    const [isActive, setIsActive] = useState(false);
    const [Loading, setLoading] = useState(false);

    useEffect(() => {
        let timerId;
        if (isActive && timeRemaining > 0) {
            timerId = setInterval(() => {
                setTimeRemaining((prevTime) => prevTime - 1);
            }, 1000); // 1000 milliseconds = 1 second
        } else if (timeRemaining === 0) {
            // Timer has reached 0, you can add logic here for what to do when the timer expires.
            setIsActive(false);
            clearInterval(timerId);
        }

        // Clean up the timer when the component unmounts
        return () => clearInterval(timerId);
    }, [isActive, timeRemaining]);

    const handlePhonLogin = () => {
        if (!otp) {
            toast.error(t("Please enter OTP"));
            return;
        }
        let params = {
            phone_code: data?.phone_code,
            phone_number: data?.phone,
            otp: otp,
            login_with: "phone_number"
        }
        setLoading(true)
        dispatch(userLogin({
            ...params, async cb(res) {
                setLoading(false)
                if (res?.status == 400) {
                    toast.error(t(res.data.message));
                }
                if (res?.status == 429) {
                    toast.error(t(res.data.message));
                    setTimeRemaining(res.data.data.lockout_period)
                    setIsActive(true)
                }
                if (res?.status == 200) {
                    if (res.data.data.user.username !== null) {
                        dispatch(setGenerateUserName({ ...authSelector?.generatedUserInfo, saved: true }))
                    }
                    navigate("/dashboard")
                }
            }
        }
        ))
    }

    const handlePhoneSignup = () => {
        if (!otp) {
            toast.error(t("Please enter OTP"));
            return
        }
        let params = {
            phone_code: data?.phone_code,
            phone: data?.phone,
            otp: otp,
        }
        setLoading(true)
        dispatch(signUpWithPhon({
            ...params, async cb(res) {
                setLoading(false)
                if (res?.status == 400) {
                    toast.error(t(res.data.message));
                }
                if (res?.status == 200) {
                    navigate("/dashboard")
                }
            }
        }))
    }

    const hadleEmailSignup = () => {
        if (!otp) {
            toast.error(t("Please enter OTP"));
            return
        }
        let params = {
            email: data?.email.trim(),
            password: data?.password,
            code: otp,
        }
        dispatch(signUpWithEmail({
            ...params, async cb(res) {
                if (res?.status == 400) {
                    toast.error(t(res.data.message));
                }
                if (res?.status == 200) {
                    navigate("/dashboard")
                }

            }
        }))
    }

    const hadleEmailOtpSend = () => {
        let params = {
            email: data?.email.trim(),
            password: data?.password,
            is_mail: true
        }
        dispatch(signUpWithEmail({
            ...params, cb(res) {
                setOtp('')
                if (res?.status == 400) {
                    toast.error(t(res.data.message));
                }
            }
        }))
    }

    const handlePhoneOtpSend = () => {
        let params = {
            phone_code: data?.phone_code,
            phone: data?.phone,
            type: data?.type == 'phoneSignup' ? 'signup' : "login",
        }
        dispatch(sendOtp({
            ...params, cb(res) {
                setOtp("");
                if (res?.status == 400) {
                    toast.error(t(res.data.message));
                }
            }
        }))
    }

    const handleResendClick = () => {
        if (data?.type == 'emailSignup') {
            hadleEmailOtpSend()
        } else {
            handlePhoneOtpSend()
        }
    }

    const handleButtonClick = () => {
        if (data?.type == 'emailSignup') {
            hadleEmailSignup()
            return
        }

        if (data?.type == 'phoneSignup') {
            handlePhoneSignup()
            return
        }

        if (data?.type == 'login') {
            handlePhonLogin()
            return
        }
    }

    const handleBack = () => {
        props.setIsOtpModal(false)
    }

    return (
        <div className='otpPage'>
            <img src={Images.OtpIcon} className='img-fluid d-block mx-auto' />

            <div className='text-center mt-2'>
                <h2 className="heading22">{t('Verify OTP')}</h2>
                <p className='subTxtPurpl12 '>{t(`Enter the code that we just sent you on your ${data.type == 'emailSignup' ? 'Email address' : 'phone number'} to sign up`)}</p>
            </div>
            <div className='row'>
                <div className='col-md-12 mt-2'>
                    <div className='optNotRecive justify-content-center'>
                        <div className='otpList mb-4'>
                            <div className='d-flex justify-content-center'>
                                <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={4}
                                    renderSeparator={<span>-</span>}
                                    renderInput={(props) => <input {...props} type="number" />}
                                    className="otpFields"
                                />
                            </div>
                        </div>

                    </div>
                </div>

                <div className='d-flex justify-content-center'>
                    <p className='sendTimeleft'>
                        <OtpTimer
                            minutes={1}
                            key={key}
                            text={t('TimeLeft')}
                            ButtonText={t("Resend")}
                            resend={handleResendClick}
                        />
                    </p>
                </div>

                <div className='col-md-12 text-center mt-2'>
                    <button className='loginBtnCommon btnYellow' type="button" onClick={() => handleButtonClick()}>
                        {Loading ? <><span className="spinner-border spinner-border-sm"></span></>
                            : <> {data?.type == 'login' ? t("Login") : t("Submit")} </>}
                    </button>
                    <button className='loginBtnCommon btnYellow mt-3' type="button" onClick={handleBack}>
                        <i class="fas fa-angle-left" /><span className='ms-2'>{t('Go Back')}</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default OTPModal;