import React from 'react'
import { Image } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Images from "../../utilities/images";
import { useTranslation } from 'react-i18next';

const SendModal = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  //Create for close the modal
  const handleClose = () => {
    //  navigate("/dashboard")
    props.onCloseModal()

  }

  
  return (
    <>
      <div className='sendModal text-center mt-3'>
        <img src={props?.email ? Images.email3 : Images.tel3} alt="tel" className='mb-3' />
        <h1 className='heading22'>{t("Sent")}</h1>
        {
          props?.email ?
            <p className='subTxtPurpl12'>{t("RegistrationSuccessMessage")}</p>
            :
            <p className='subTxtPurpl12'>{t("RegistrationSuccessMessageMobile")}</p>

        }
        <div className='col-md-12'>
          <button className='loginBtnCommon w-100' type="button" onClick={() => handleClose()}>{t("Okay")}</button>
        </div>
      </div>
    </>
  )
}

export default SendModal