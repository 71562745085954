import React from 'react'
import { Image } from 'react-bootstrap'
import * as Images from "../../../utilities/images";
import { useTranslation } from 'react-i18next';

const EmailModal = (props) => {
  const { t } = useTranslation();

  return (
    <>
    <div className='emailModal mt-3'>
    <div className='sendModal text-center'>
        <img src={Images.email3} alt="tel" className='mb-3' />
        <h1 className='heading22'>{t('CheckYourMail')}</h1>
            <p className='subTxtPurpl12'>{t('PasswordRecoveryLinkSent')}</p> 
        <div className='col-md-12'>
          <button className='loginBtnCommon w-100' type="button" onClick={() => props.onCloseModal()}>{t('Okay')}</button>
        </div>
      </div>
    </div>
    </>
  )
}

export default EmailModal