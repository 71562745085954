import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthSelector } from "../../../redux/selector/auth";
import { logout } from "../../../redux/slices/auth";
import CustomModal from "../../components/common/shared/CustomModal";
import ShowDetailsModal from "../../components/common/showDtailModal";
import { useTranslation } from 'react-i18next';
import UserNameModal from "../../components/common/userNameModal";

const AccountDashboard = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const authSelector = useAuthSelector();
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
    type: "",
  });

  // console.log("authSelector", authSelector);
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  //Create for user logout
  const handleLogout = () => {
    let params = {}
    dispatch(logout({
      ...params, cb(res) {
        navigate("/")
      }
    }))
  }


  useEffect(() => {
    console.log(location.state, '+++')
    document.title = "Dashboard";
    if (location?.state?.username) {
      // setModalDetail({ show: true, flag: "emailModal" });
      // setKey(Math.random());
      window.history.replaceState(null, '')
    }

    if (authSelector?.isLoggedIn && authSelector?.userInfo?.user?.username == null) {
      setModalDetail({ show: true, flag: "userNameModal" });
      setKey(Math.random());
    }

    if (authSelector?.isLoggedIn && authSelector?.userInfo?.user?.username != null) {
      if (!authSelector?.generatedUserInfo?.saved) {
        setModalDetail({ show: true, flag: "userNameModal" });
        setKey(Math.random());
      }
    }
  }, []);

  return (
    <>

      <div className="dashboard">
        <div className="d-block text-center">

          <h1>{t("Welcome Betting Website")}</h1>
          {authSelector?.generatedUserInfo ? <>
            {authSelector?.generatedUserInfo?.data?.username ?
              <>
                <button className="loginBtnCommon d-block mx-auto mb-2" type="button" onClick={() => { setModalDetail({ show: true, flag: "emailModal" }); setKey(Math.random()); }}>{t("GetCredentials")}</button>
              </>
              : ""}
          </>
            : ""}


          <button className="loginBtnCommon btn-danger d-block mx-auto" type="button" onClick={() => handleLogout()}>{t("Logout")}</button>
        </div>
      </div>

      <CustomModal
        key={key}
        showModal={modalDetail.show}
        title={modalDetail.title}
        dialogClassName="modal-dialog-centered"
        backdrop="static"
        ids={modalDetail.flag === "userNameModal" ? "userNameModal" : "credentialsModal"}
        newIds={modalDetail.flag === "emailModal" ? "emailModal" : ""}
        onCloseModal={() => handleOnCloseModal()}
        showCloseBtn={"show"}
        header={<header></header>}
        child={modalDetail.flag === "emailModal" ?
          <ShowDetailsModal onCloseModal={() => handleOnCloseModal()} details={location?.state ? location?.state : authSelector.generatedUserInfo.data} />
          :
          modalDetail.flag === "userNameModal" ?
            <>
              <UserNameModal onCloseModal={() => handleOnCloseModal()} hasData={authSelector.generatedUserInfo.data} />
            </>
            : ""
        }
      />
    </>
  )
}

export default AccountDashboard
