import React, { useEffect, useState } from "react"
import * as Images from "../../../utilities/images";
import { useAuthSelector } from "../../../redux/selector/auth";
import { Link, useNavigate } from "react-router-dom";
import ReactFlagsSelect from "react-flags-select";
import CustomModal from "./shared/CustomModal";
import SignupModal from "./signupModal";
import LoginModal from "./loginModal";
import GetCreadentials from "./getCreadentials";
import SendModal from "../../containers/sendModal";
import ForgetModal from "./forgetModal";
import EmailModal from "./emailModal";
import UserNameModal from "./userNameModal";
import { useTranslation } from 'react-i18next';
import CreateUserNameModal from "./createUserNameModal";

const MainNav = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const language = localStorage.getItem('language');
    const authSelector = useAuthSelector();
    const [select, setSelect] = useState(language == "en" ? "US" : language == "az" ? "AZ" : language == "tr" ? "TR" : language == "ru" ? "RU" : "US");
    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [typeFlag, setTypeFlag] = useState("");
    const [countryName, setCountryName] = useState("");
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
        type: "",
    });

    // console.log("authSelectornev", authSelector)

    const onSelect = (code) => {
        setSelect(code);
        if (code == "US") {
            i18n.changeLanguage('en');
            localStorage.setItem("language", "en")
        }
        else if (code == "AZ") {
            i18n.changeLanguage('az');
            localStorage.setItem("language", "az")
        }
        else if (code == "RU") {
            i18n.changeLanguage('ru');
            localStorage.setItem("language", "ru")
        }
        else if (code == "TR") {
            i18n.changeLanguage('tr');
            localStorage.setItem("language", "tr")
        }

    }


    const googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
            {
                //  pageLanguage: 'en',
                includedLanguages: 'en,fr,hi,ru',
                autoDisplay: true,
                defaultLanguage: 'en',
            },
            "google_translate_element"
        );
    };

    setTimeout(() => {
        const selectElement = document.querySelector('.goog-te-combo');
        if (selectElement) {
            const optionElements = selectElement.querySelectorAll('option');

            optionElements.forEach((option) => {
                if (option.textContent === 'Select Language') {
                    option.remove(); // Remove the option from the dropdown
                }
            })
        }

    }, 1000);



    useEffect(() => {
        var addScript = document.createElement("script");
        addScript.setAttribute(
            "src",
            "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
        );
        document.body.appendChild(addScript);
        window.googleTranslateElementInit = googleTranslateElementInit;
    }, []);



    useEffect(() => {
        fetch('https://geolocation-db.com/json/') // Replace with your server-side endpoint
            .then((response) => response.json())
            .then((data) => {
                setCountryName(data.country_code.toLowerCase())
            })
            .catch((error) => console.error('Error fetching IP address:', error));

    }, []);



    //Create for close the modal.
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };

    //Create for change the modal.
    const handleModalChange = (flag, userName, password, type, email = null) => {
        setUserName(userName);
        setPassword(password);
        setTypeFlag(type);

        if (flag == "forgetModal") {
            setModalDetail({ show: true, flag: flag });
            setKey(Math.random());
        }
        if (flag == "forgetModal") {
            setModalDetail({ show: true, flag: flag });
            setKey(Math.random());
        }
        if (flag == "emailModal") {
            setModalDetail({ show: true, flag: flag });
            setKey(Math.random());
        }
        if (flag == "userNameModal") {
            setModalDetail({ show: true, flag: flag });
            setKey(Math.random());
        }
        if (flag == "createUserNameModal") {
            setModalDetail({ show: true, flag: flag });
            setKey(Math.random());
        }
        if (flag == "loginModal") {
            setModalDetail({ show: true, flag: flag });
            setKey(Math.random());
        }
        if (flag == "signupModal") {
            setModalDetail({ show: true, flag: flag });
            setKey(Math.random());
        }
    }


    useEffect(() => {
        if (authSelector?.isLoggedIn && authSelector?.userInfo?.user?.username == null) {
            setModalDetail({ show: true, flag: "userNameModal" });
            setKey(Math.random());
        }
    }, [])

    return (
        <>
            <div className="header" >
                <div className="container-fluid">
                    <nav className="navbar  py-0">
                        <div className="headerLeft_
                        ">
                            <Link className="navbar-brand" href="/">
                                <img src={Images.logo} alt="" className="logo" />
                            </Link>
                        </div>
                        <div className="navbar-collapse justify-content-end" id="navbarTogglerDemo02">
                            {authSelector?.isLoggedIn ? <>
                                <div className="loginBtns">
                                    <button className="signBtn me-2"
                                        onClick={() => navigate("/dashboard")}
                                    >{t('Dashboard')}</button>
                                </div>
                            </>
                                :
                                <div className="loginBtns">
                                    <button className="signBtn me-2"
                                        onClick={() => {
                                            setModalDetail({ show: true, flag: "newmodal", type: "signupModal" });
                                            setKey(Math.random());
                                        }}
                                    >{t('signup')} </button>
                                    <button className="loginBtn me-2"
                                        onClick={() => {
                                            setModalDetail({ show: true, flag: "newmodal", type: "loginModal" });
                                            setKey(Math.random());
                                        }}
                                    >{t('login')} </button>
                                    <div className="country_select_"  >
                                        <ReactFlagsSelect
                                            selected={select}
                                            onSelect={onSelect}
                                            defaultCountry="US"
                                            countries={["US", "AZ", "TR", "RU"]}
                                            customLabels={{ "US": "ENG", "AZ": "AZE", "TR": "TUR", "RU": "RUS" }}
                                            className="countrySelect"
                                            selectedSize={4}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </nav>
                </div>
            </div>
            <CustomModal
                key={key}
                showModal={modalDetail.show}
                setShowModal={setModalDetail}
                title={modalDetail.title}
                type={modalDetail.type}
                className={modalDetail.flag === "signupModal" || modalDetail.flag === "newmodal" ? "signupModal" : ""}
                backdrop={false}
                ids={modalDetail.flag === "signupModal" || modalDetail.flag === "newmodal" ? "signupModal" : modalDetail.flag === "loginModal" ? "loginModal" : modalDetail.flag === "credentialsModal" ? "credentialsModal" : modalDetail.flag === "sendModal" ? "credentialsModal" : modalDetail.flag === "credentialsEmailModal" ? "credentialsModal" : modalDetail.flag === "sendEmailModal" ? "credentialsModal" : modalDetail.flag === "forgetModal" ? "forgetModal" : modalDetail.flag === "emailModal" ? "credentialsModal" : modalDetail.flag === "userNameModal" || modalDetail.flag === "createUserNameModal" ? "userNameModal" : ''}
                onCloseModal={() => handleOnCloseModal()}
                showCloseBtn={modalDetail.flag === "emailModal" || modalDetail.flag === "sendModal" || modalDetail.flag === "sendEmailModal" ? "hide" : "show"}
                header={
                    modalDetail.flag === "signupModal" || modalDetail.flag === "newmodal" ?
                        <>
                            <header></header>
                        </>
                        :
                        modalDetail.flag === "loginModal" ?
                            <>
                                <header></header>
                            </>

                            : null
                }
                child={
                    modalDetail.flag === "newmodal" ?
                        <>
                            <SignupModal onCloseModal={() => handleOnCloseModal()} handleModalChange={(flag, userName, password, hasData) => handleModalChange(flag, userName, password, hasData)} countryName={countryName} type={modalDetail?.type} />
                        </>
                        :

                        modalDetail.flag === "signupModal" ?
                            <>
                                <SignupModal onCloseModal={() => handleOnCloseModal()} handleModalChange={(flag, userName, password) => handleModalChange(flag, userName, password)} countryName={countryName} />
                            </>
                            :
                            modalDetail.flag === "loginModal" ?
                                <>
                                    <LoginModal onCloseModal={() => handleOnCloseModal()} handleModalChange={(e) => handleModalChange(e)} countryName={countryName} />
                                </>
                                :
                                modalDetail.flag === "sendModal" ?
                                    <>
                                        <SendModal onCloseModal={() => handleOnCloseModal()} />
                                    </>
                                    : modalDetail.flag === "sendEmailModal" ?
                                        <>
                                            <SendModal email={true} onCloseModal={() => handleOnCloseModal()} />
                                        </>
                                        :
                                        modalDetail.flag === "credentialsEmailModal" ?
                                            <>
                                                <GetCreadentials onCloseModal={() => handleOnCloseModal()} email={true} handleModalChange={(e) => handleModalChange(e)} userName={username} password={password} />
                                            </>
                                            : modalDetail.flag === "credentialsModal" ?
                                                <>
                                                    <GetCreadentials onCloseModal={() => handleOnCloseModal()} handleModalChange={(e) => handleModalChange(e)} userName={username} password={password} countryName={countryName} />
                                                </>
                                                :
                                                modalDetail.flag === "forgetModal" ?
                                                    <>
                                                        <ForgetModal onCloseModal={() => handleOnCloseModal()} email={true} handleModalChange={(e) => handleModalChange(e)} />
                                                    </>
                                                    :
                                                    modalDetail.flag === "emailModal" ?
                                                        <>
                                                            <EmailModal onCloseModal={() => handleOnCloseModal()} />
                                                        </>
                                                        :
                                                        modalDetail.flag === "userNameModal" ?
                                                            <>
                                                                <UserNameModal onCloseModal={() => handleOnCloseModal()} userName={username} password={password} />
                                                            </>
                                                            :
                                                            modalDetail.flag === "createUserNameModal" ?
                                                                <>
                                                                    <CreateUserNameModal onCloseModal={() => handleOnCloseModal()} firstName={username} secondName={password} typeFlag={typeFlag} />
                                                                </>
                                                                :
                                                                null
                }
            />
        </>
    )

}

export default MainNav;