import React, { useState, useEffect, } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { checkToken, resetPassword, setGenerateUserName } from "../../../redux/slices/auth";
import CustomModal from "../../components/common/shared/CustomModal";
import TokenExpireModal from "../../components/common/tokenExipreModal";
import { useTranslation } from 'react-i18next';
import { useAuthSelector } from "../../../redux/selector/auth";
import { toast } from "react-toastify";

const ResetPassword = () => {
    const { t } = useTranslation();
    let { resetPasswordToken } = useParams();
    const authSelector = useAuthSelector();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [logInError, setLogInError] = useState("");
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
        type: "",
    });

    const handleResetPassword = () => {
        if (!newPassword) {
            toast.error(t("Please enter new password"))
            // setLogInError({ emailPassword: t("Please enter new password") });
            return;
        }
        else if (!confirmPassword) {
            toast.error(t("Please enter confirm password"))
            // setLogInError({ ConfirmPassword: t("Please enter confirm password") });
            return;
        }
        else if (newPassword != confirmPassword) {
            toast.error(t("ConformPassword should be match"))
            // setLogInError({ ConfirmPassword: t("ConformPassword should be match") });
            return;
        }
        let params = {
            token: resetPasswordToken,
            new_password: newPassword,
        }
        dispatch(resetPassword({
            ...params, cb(res) {
                if (res?.status == 400) {
                    toast.error(t(res.data.message))
                    // setLogInError({ error: t(res.data.message) });
                }
                if (res?.status == 200) {
                    dispatch(setGenerateUserName({ ...authSelector?.generatedUserInfo, saved: true }))
                    navigate("/dashboard")
                    setLogInError("")
                }
            }
        }))

    }

    const checkTokenExpiry = () => {
        let params = {
            token: resetPasswordToken,
        }
        dispatch(checkToken({
            ...params, cb(res) {
                if (res?.status == 400) {
                    setModalDetail({ show: true, flag: "emailModal" });
                    setKey(Math.random());
                }
                if (res?.status == 200) {
                    navigate(`/resetPassword/${resetPasswordToken}`)
                }
            }
        }))
    }

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };


    useEffect(() => {
        document.title = "resetPassword";
        checkTokenExpiry()
    }, []);

    return (
        <>
            <div className="resetPasswordbox_">
                <div className="col-lg-3 col-md-3 col-sm-12">
                    <form className="customForm resetForm_">
                        {/* <div className='container'> */}
                        <h2 className="heading22 mb-5">{t("ResetPassword")}</h2>
                        <div className='row'>
                            <div className='col-md-12 mb-4'>
                                <div className="form-group">
                                    <label htmlFor="username" className="form-label"> {t("NewPassword")}</label>
                                    <input type="text" className="form-input" placeholder={t("EnterPassword")} value={newPassword} onChange={(e) => { setNewPassword(e.target.value); setLogInError("") }} />
                                    {/* <img src={Images.copy} alt='copy icon' className='copyIcon'/> */}
                                    {logInError ?
                                        <span className={logInError?.emailPassword ? 'validationMsg' : "d-none"}>{logInError?.emailPassword ? <>{t(`${logInError?.emailPassword}`)}</> : ""}</span>
                                        : ""}
                                </div>
                            </div>
                            <div className='col-md-12 mb-4'>
                                <div className="form-group">
                                    <label htmlFor="password" className="form-label"> {t("ConfirmPassword")}</label>
                                    <input type="text" className="form-input" placeholder={t("EnterConfirmPassword")} value={confirmPassword} onChange={(e) => { setConfirmPassword(e.target.value);; setLogInError("") }} />
                                    {/* <img src={Images.copy} alt='copy icon' className='copyIcon'/> */}
                                    {logInError ?
                                        <span className={logInError?.ConfirmPassword ? 'validationMsg' : "d-none"}>{logInError?.ConfirmPassword ? <>{t(`${logInError?.ConfirmPassword}`)}</> : ""}</span>
                                        : ""}
                                </div>
                            </div>
                            {logInError ?
                                <span className={logInError?.error ? 'validationMsg' : "d-none"}>{logInError?.error}</span>
                                : ""}


                            <div className='col-md-12 text-center mt-2'>
                                <button className='loginBtnCommon btnYellow' type="button" onClick={() => handleResetPassword()}> {t("Reset")}</button>
                            </div>

                        </div>
                        {/* </div> */}
                    </form>
                </div>
            </div>
            <CustomModal
                key={key}
                showModal={modalDetail.show}
                // setShowModal={setModalDetail}
                title={modalDetail.title}
                // className="signupModal"
                dialogClassName="modal-dialog-centered"
                backdrop="static"
                ids="credentialsModal"
                onCloseModal={() => handleOnCloseModal()}
                showCloseBtn={"show"}
                header={<header></header>}
                child={modalDetail.flag === "emailModal" ?
                    <TokenExpireModal onCloseModal={() => handleOnCloseModal()} />
                    : ""
                }
            />
        </>
    )
}

export default ResetPassword
