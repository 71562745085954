import React, { useEffect } from "react"
import * as Images from "../../../utilities/images";
import { useLocation } from "react-router-dom";

const Home = () => {
    const location = useLocation();

    useEffect(() => {
        if (location.state != null) {
            window.location.reload();
        }
        window.history.replaceState({}, document.title);
    }, [])

    return (
        <>
            <section className="banner">
                <img src={Images.mobbdimg} className="mobbannerImg" />
                <img src={Images.banner} className="bannerImg" />
                <div className="container-fluid">
                    <div className="row text-center">
                        <div className="col-12">
                            <div className="mobileLogo_">
                                <img src={Images.mobileLogo} alt="" className="logo" />
                                {/* <span className="d-block">PDF Conversion</span> */}
                            </div>
                            <img src={Images.play} className="playImg img-fluid" draggable={false} />
                            <img src={Images.mobileText} className="playImg mobplay img-fluid" />
                        </div>
                        <div className="text-center" style={{ zIndex: '1' }}>
                            <button className="loginBtnCommon btnYellow" > <img src={Images.first} alt="img" draggable={false} /> </button>
                            <button className="loginBtnCommon btnPurple"> <img src={Images.demo} alt="img" draggable={false} /></button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}

export default Home;