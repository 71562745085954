import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuthSelector } from "../../redux/selector/auth";

const PrivateCheck = ({ auth }) => {
    const navigate = useNavigate();
    const authSelector = useAuthSelector();

    //add this authentication after implimenting login 
    const authToken = localStorage.getItem("authToken") ? true : false;

    useEffect(() => {
        if (auth && !authToken && !authSelector?.isLoggedIn) {
            navigate("/")
        }
    }, [])

    return (
        <Outlet />

    )

}


export default PrivateCheck;
