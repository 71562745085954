import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { confirmUserName, imageUpload, updateWithUserName, signUpWithUserName, setGenerateUserName, setUpdateWithUserName } from '../../../redux/slices/auth';
import * as Images from "../../../utilities/images";
import { useTranslation } from 'react-i18next';
import { useAuthSelector } from '../../../redux/selector/auth';
import { toast } from 'react-toastify';


const UserNameModal = (props) => {
  const { t } = useTranslation();
  const authSelector = useAuthSelector();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userName, setUserName] = useState(props.userName ? props.userName : authSelector?.userInfo?.user?.username);
  const [imageUrl, setImageUrl] = useState(authSelector?.userInfo?.user?.profile_image ? authSelector?.userInfo?.user?.profile_image : "");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorBlurMessage, setErrorBlurMessage] = useState("");
  const [bulerLoading, setBulerLoading] = useState(false);


  const isValidUsername = (username) => {
    // Regular expression for username validation
    const userNameRegex = /^[a-zA-Z0-9]+$/;
    return userNameRegex.test(username);
  };

  //Create for image upload for username.
  const onSelectFiles = (e) => {
    let imageFile
    imageFile = e.target.files[0];
    if (!imageFile) {
      toast.error(t("Please select image"));
      // setErrorMessage({ image: t("Please select image") });
      return;
    }
    let formData = new FormData();
    formData.append("file", imageFile)
    let params = {
      file: formData,
    }
    setLoading(true)
    dispatch(imageUpload({
      ...params, cb(res) {
        setLoading(false);
        if (res?.status == 400) {
          toast.error(t(res.data.message));
          // setErrorMessage({ error: t(res.data.message) });
        }
        if (res?.status == 200) {
          setImageUrl(res.data.data.profile_image)
        }
      }
    }))
  }


  //Create for update username.
  const hadleUserNameClick = () => {
    if (!userName && !props.hasData) {
      toast.error(t('Please enter userName'));
      // setErrorMessage({ email: t('Please enter userName') });
      return;
    }
    else if (!isValidUsername(userName) && !props.hasData) {
      toast.error(t("Please enter valid userName"));
      // setErrorMessage({ email: t("Please enter valid userName") });
      return;
    }
    let params = {
      username: props.hasData ? props.hasData.username : userName,
    }
    if (props.hasData) {
      params = { ...params, is_username: true }
    }
    if (props.password != null) {
      params = { ...params, password: props.password }
    } else {
      params = { ...params, is_username: true }
    }
    if (imageUrl) {
      params = { ...params, profile_image: imageUrl }
    }
    dispatch(updateWithUserName({
      ...params, cb(res) {
        if (res?.status == 400) {
          toast.error(t(res.data.message));
          // setErrorMessage({ error: t(res?.data?.message) });
        }
        if (res?.status == 200) {
          navigate("/dashboard")
          setErrorMessage("")
          props.onCloseModal()
        }
      }
    }))

    // if (authSelector?.generatedUserInfo?.data?.username) {
    dispatch(setGenerateUserName({ ...authSelector?.generatedUserInfo, saved: true }))
    // }
  }


  const handleInputBlur = () => {
    if (!userName) {
      setErrorMessage({ email: t("Please enter username") });
      return;
    }
    else if (!isValidUsername(userName)) {
      setErrorMessage({ email: t("Please enter valid username") });
      return;
    }
    let params = {
      username: userName
    }
    setBulerLoading(true)
    dispatch(confirmUserName({
      ...params, cb(res) {
        setBulerLoading(false)
        if (res?.status == 400) {
          toast.error(t(res.data.message));
          // setErrorBlurMessage({ email: t(res.data.message) });
        }
        if (res?.status == 200) {
          setErrorBlurMessage("")
        }
      }
    }))

  };

  return (
    <>
      <div className='row'>
        <div className='userName'>
          {loading ?
            <>
              <span className="spinner-border spinner-border-sm ImageLoader"></span>
            </> :
            <img src={imageUrl ? imageUrl : Images.userImg} alt="user Imgage" className='userImg' />
          }
          <label htmlFor="file-upload" className="custom-file-upload">
            <i className="fas fa-camera cameraIcon"></i>
          </label>
          <input
            id="file-upload"
            type="file"
            name="profile_image"
            className="d-none"
            onChange={(e) => { onSelectFiles(e); setErrorMessage("") }}
          />
        </div>
        <div className='col-md-12 mb-3'>
          <div className="form-group">
            <div className='position-relative mb-2 mt-5'>
              <label htmlFor="username" className="form-label">{t("Username")}</label>
              <input id="username" className="form-input genertatUserName_" placeholder={t("EnterUsername")}
                value={props.hasData ? props.hasData.username : userName}
                disabled={props.hasData || props.password === null}
                onChange={(e) => { setUserName(e.target.value); setErrorMessage(""); setErrorBlurMessage("") }}
              />
            </div>
            {errorMessage ?
              <span className={errorMessage?.email ? 'validationMsg' : "d-none"}>{errorMessage?.email ? <>{t(`${errorMessage?.email}`)}</> : ""}</span>
              : ""}
            {errorMessage ?
              <span className={errorMessage?.error ? 'validationMsg' : "d-none"}>{errorMessage?.error}</span>
              : ""}
            {errorBlurMessage ?
              <span className={errorBlurMessage?.email ? 'validationMsg' : "d-none"}>{errorBlurMessage?.email}</span>
              : ""}
          </div>
        </div>
        <div className='col-md-12'>
          <button className='loginBtnCommon btnYellow mw-100 my-2' type='button'
            disabled={errorMessage?.email || errorBlurMessage?.email}
            onClick={() => hadleUserNameClick()} >
            {bulerLoading ? <><span className="spinner-border spinner-border-sm"></span></>
              : <> {t("Continue")}</>}

          </button>
        </div>
      </div>
    </>
  )
}

export default UserNameModal