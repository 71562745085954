import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuthSelector } from '../../../redux/selector/auth';
import { userLogin, forgetPassword } from '../../../redux/slices/auth';
import { useTranslation } from 'react-i18next';


const ForgetModal = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authSelector = useAuthSelector();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation();


  //Create for forget password
  const handleContinue = () => {
    if (!email) {
      toast.error(t("Please enter email"))
      // setEmailError(t("Please enter email"));
      return;
    }
    let params = {
      email: email.trim(),
    }
    setLoading(true)
    dispatch(forgetPassword({
      ...params, cb(res) {
        setLoading(false)
        if (res?.status == 400) {
          toast.error(t(res.data.message))
          // setError(t(res.data.message));
        }
        if (res?.status == 200) {
          props?.handleModalChange("emailModal")
        }
        // props?.handleModalChange("emailModal")
        // navigate("/dashboard")
      }
    }))
  }

  return (
    <>
      <div className='forgotModal'>
        <h1 className='heading22'>
          {t('Forgot Password?')}
        </h1>
        <div className='hr'></div>
        <p className='subTxtPurpl12'>{t('Enter the email address associated with your account.')}</p>
        <div className='row'>
          <div className='col-md-12'>
            <div className="form-group">
              <label htmlFor="username" className="form-label">{t("EmailAddress")}</label>
              <input type="email" id="username" className="form-input" placeholder={t("EnterEmail")} value={email} onChange={(e) => { setEmail(e.target.value); setEmailError("") }} />
            </div>
            {emailError ?
              <span className={emailError ? 'validationMsg' : "d-none"}>{emailError ? <>{t(`${emailError}`)}</> : ""}</span>
              : ""}<br></br>
            {error ?
              <span className={error ? 'validationMsg' : "d-none"}>{error}</span>
              : ""}<br></br>
          </div>
        </div>
        <div className='text-center mt-2'>
          <button type="button" onClick={() => handleContinue()} disabled={loading} className="loginBtnCommon btnYellow">
            {loading ?
              <>
                <span className="spinner-border spinner-border-sm"></span>
              </>
              :
              <> {t("Continue")} </>
            }
          </button>
        </div>
      </div>
    </>
  )
}

export default ForgetModal