import './App.css';
import React, { useEffect } from 'react';
import { BrowserRouter } from "react-router-dom";
import Router from "./routes";
import './public/css/custom.css';
import './public/css/login.css';
import './public/css/responsive.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux'
import store from './redux';
import { persistor } from './redux';
import { PersistGate } from 'redux-persist/integration/react';
import { useTranslation } from "react-i18next";

function App() {

  const { t, i18n } = useTranslation();


  return (
    <>
      {/* <React.StrictMode> */}
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </PersistGate>
      </Provider>
      <ToastContainer
        // limit={1}
        autoClose={500}
      // position="top-right"
      // hideProgressBar={false}
      // newestOnTop={true}
      // closeOnClick
      // rtl={false}
      // pauseOnFocusLoss
      // draggable
      // pauseOnHover
      />
      {/* </React.StrictMode> */}
    </>
  );
}

export default App;
