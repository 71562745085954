import { all, call, put, takeLatest } from 'redux-saga/effects'
import { ApiClient } from '../../../utilities/api';
import { onErrorStopLoad, setLogout, setGenerateUserName, setUserNameLimit, setSendCredentialToMail, setSendCredentialToPhone, setCheckToken, setSignUpWithUserName, setUserSocialLoginSignup, setImageUpload, setUpdateWithUserName, setResetPassword, setConfirmEmail, setConfirmUserName, setConfirmPhoneNo, setSendOtp, setForgetPassword, setSignUpWithPhon, setUserLogin, setSignUpWithEmail, } from '../../slices/auth';
import ApiPath from '../../../constants/apiPath';
import { toast } from 'react-toastify';
import { t } from 'i18next';

// Worker saga will be fired on USER_FETCH_REQUESTED actions
function* userLogin(action) {
  try {
    const resp = yield call(ApiClient.post, action.url = ApiPath.AuthApiPath.LOGIN_USER, action.payload = action.payload);
    if (resp.status) {
      localStorage.setItem("authToken", resp.data.data.token ? resp.data.data.token : "")
      yield put(setUserLogin(resp.data.data));
      yield call(action.payload.cb, action.res = resp)
      //  toast.success(action.res.data.message);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield call(action.payload.cb, action.res = e.response)
    yield put(setUserLogin());


  }
}

function* signUpWithUserName(action) {
  try {
    const resp = yield call(ApiClient.post, action.url = ApiPath.AuthApiPath.SIGNUP_WITH_USERNAME, action.payload = action.payload);
    if (resp.status) {
      localStorage.setItem("authToken", resp.data.data.token ? resp.data.data.token : "")
      yield put(setSignUpWithUserName(resp.data.data));
      yield call(action.payload.cb, action.res = resp)

    }
    else {
      throw resp
    }
  } catch (e) {
    yield call(action.payload.cb, action.res = e.response)


  }
}


function* userSocialLoginSignup(action) {
  try {
    const resp = yield call(ApiClient.post, action.url = ApiPath.AuthApiPath.USER_SOCIAL_LOGIN_SIGNUP, action.payload = action.payload);
    if (resp.status) {
      localStorage.setItem("authToken", resp?.data?.data?.token ? resp?.data?.data?.token : "")
      yield put(setUserSocialLoginSignup(resp?.data?.data));
      yield call(action.payload.cb, action.res = resp)

    }
    else {
      throw resp
    }
  } catch (e) {
    yield call(action.payload.cb, action.res = e.response)


  }
}

function* sendCredentialToMail(action) {
  try {
    const resp = yield call(ApiClient.post, action.url = ApiPath.AuthApiPath.SEND_CERDENTIAL_MALE, action.payload = action.payload);
    if (resp.status) {
      yield put(setSendCredentialToMail(resp?.data?.data));
      yield call(action.payload.cb, action.res = resp)
    }
    else {
      throw resp
    }
  } catch (e) {
    yield call(action.payload.cb, action.res = e.response)
  }
}

function* sendCredentialToPhone(action) {
  try {
    const resp = yield call(ApiClient.post, action.url = ApiPath.AuthApiPath.SEND_CERDENTIAL_PHONE, action.payload = action.payload);
    if (resp.status) {
      localStorage.setItem("authToken", resp?.data?.data?.token ? resp?.data?.data?.token : "")
      yield put(setSendCredentialToPhone(resp?.data?.data));
      yield call(action.payload.cb, action.res = resp)

    }
    else {
      throw resp
    }
  } catch (e) {
    yield call(action.payload.cb, action.res = e.response)


  }
}

function* updateWithUserName(action) {
  try {
    const resp = yield call(ApiClient.put, action.url = ApiPath.AuthApiPath.UPDATE_WITH_USERNAME, action.payload = action.payload);
    if (resp.status) {
      //  localStorage.setItem("authToken", resp.data.data.token ? resp.data.data.token : "")
      yield put(setUpdateWithUserName(resp.data.data));
      yield call(action.payload.cb, action.res = resp)

    }
    else {
      throw resp
    }
  } catch (e) {
    yield call(action.payload.cb, action.res = e.response)


  }
}



function* resetPassword(action) {
  const paramToSend = { ...action.payload }
  delete paramToSend.cb
  try {
    const resp = yield call(ApiClient.put, action.url = ApiPath.AuthApiPath.RESET_PASSWORD, action.payload = action.payload);
    if (resp.status) {
      localStorage.setItem("authToken", resp.data.data.token ? resp.data.data.token : "")
      yield put(setResetPassword(resp?.data?.data));
      yield call(action.payload.cb, action.res = resp)
    }
    else {
      throw resp
    }
  } catch (e) {
    yield call(action.payload.cb, action.res = e.response)


  }
}




function* checkToken(action) {
  const paramToSend = { ...action.payload }
  delete paramToSend.cb
  try {
    const resp = yield call(ApiClient.get, action.url = `${ApiPath.AuthApiPath.CHECK_TOKEN}?token=${action.payload.token}`, action.payload = action.payload);
    if (resp.status) {
      localStorage.setItem("authToken", resp.data.data.token ? resp.data.data.token : "")
      yield put(setCheckToken(resp));
      yield call(action.payload.cb, action.res = resp)

    }
    else {
      throw resp
    }
  } catch (e) {
    yield call(action.payload.cb, action.res = e.response)
    yield put(setCheckToken());


  }
}




function* signUpWithEmail(action) {

  try {

    const resp = yield call(ApiClient.post, action.url = ApiPath.AuthApiPath.SIGNUP_WITH_EMAIL, action.payload = action.payload);
    if (resp.status) {
      if (resp.data.data) {
        localStorage.setItem("authToken", resp.data.data.token ? resp.data.data.token : "")
        yield put(setSignUpWithEmail(resp.data.data));
      }
      yield call(action.payload.cb, action.res = resp)

    }
    else {
      throw resp
    }
  } catch (e) {

    yield call(action.payload.cb, action.res = e.response)


  }
}

function* signUpWithPhon(action) {
  try {
    const resp = yield call(ApiClient.post, action.url = ApiPath.AuthApiPath.SIGNUP_WITH_PHONE, action.payload = action.payload);
    if (resp.status) {
      localStorage.setItem("authToken", resp.data.data.token ? resp.data.data.token : "")
      yield put(setSignUpWithPhon(resp.data.data));
      yield call(action.payload.cb, action.res = resp)

    }
    else {
      throw resp
    }
  } catch (e) {
    // yield put(setConfirmEmail(e.response));
    yield call(action.payload.cb, action.res = e.response)


  }
}


function* generateUserName(action) {
  try {
    const resp = yield call(ApiClient.get, action.url = ApiPath.AuthApiPath.GENERATE_USER);
    if (resp.status) {
      yield put(setGenerateUserName(resp.data));
      yield call(action.payload.cb, action.res = resp)

    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(onErrorStopLoad())
    toast.error(t(e.response.data.message));
  }
}

function* userNameLimit(action) {
  try {
    const resp = yield call(ApiClient.get, action.url = ApiPath.AuthApiPath.USERNAME_LIMIT);
    if (resp.status) {
      yield put(setUserNameLimit(resp.data));
      yield call(action.payload.cb, action.res = resp)

    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(onErrorStopLoad())

  }
}


function* sendOtp(action) {
  try {
    const resp = yield call(ApiClient.post, action.url = ApiPath.AuthApiPath.SEND_OTP, action.payload = action.payload);
    if (resp.status) {
      yield put(setSendOtp(resp.data));
      yield call(action.payload.cb, action.res = resp)

    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(onErrorStopLoad())
    yield call(action.payload.cb, action.res = e.response)

  }
}

function* forgetPassword(action) {
  try {
    const resp = yield call(ApiClient.post, action.url = ApiPath.AuthApiPath.FORGET_PASSWORD, action.payload = action.payload);
    if (resp.status) {
      yield put(setForgetPassword(resp.data));
      yield call(action.payload.cb, action.res = resp)

    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(onErrorStopLoad(e.response))
    yield call(action.payload.cb, action.res = e.response)

  }
}

function* confirmEmail(action) {
  try {
    const resp = yield call(ApiClient.post, action.url = ApiPath.AuthApiPath.CONFIRM_EMAIL, action.payload = action.payload);
    if (resp.status) {
      yield put(setConfirmEmail(resp.data));
      yield call(action.payload.cb, action.res = resp)
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setConfirmEmail(e.response));
    yield call(action.payload.cb, action.res = e.response)


  }
}

function* confirmUserName(action) {
  try {
    const resp = yield call(ApiClient.post, action.url = ApiPath.AuthApiPath.CONFIRM_USERNAME, action.payload = action.payload);
    if (resp.status) {
      yield put(setConfirmUserName(resp.data));
      yield call(action.payload.cb, action.res = resp)
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setConfirmUserName(e.response));
    yield call(action.payload.cb, action.res = e.response)


  }
}

function* confirmPhoneNo(action) {
  try {
    const resp = yield call(ApiClient.post, action.url = ApiPath.AuthApiPath.CONFIRM_PHONE, action.payload = action.payload);
    if (resp.status) {
      yield put(setConfirmPhoneNo(resp.data));
      yield call(action.payload.cb, action.res = resp)
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setConfirmPhoneNo(e.response));
    yield call(action.payload.cb, action.res = e.response)


  }
}

function* imageUpload(action) {
  try {
    const resp = yield call(ApiClient.postFormData, action.url = ApiPath.AuthApiPath.IMAGE_UPLOAD, action.payload = action.payload);
    if (resp.status) {
      yield put(setImageUpload(resp.data));
      yield call(action.payload.cb, action.res = resp)
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setImageUpload(e.response));
    yield call(action.payload.cb, action.res = e.response)


  }
}




function* userLogout(action) {
  try {
    const resp = yield call(ApiClient.get, action.url = ApiPath.AuthApiPath.LOGOUT_USER, action.payload = action.payload);
    if (resp.status) {
      yield call(action.payload.cb, action.res = resp)
      localStorage.removeItem('authToken');
      localStorage.removeItem('persist:root');
      // toast.success(action.res.data.message);
      yield put(setLogout())
    }
    else {
      throw resp
    }
  } catch (e) {
    toast.error(t(e.response.data.message));
  }
}


function* authSaga() {
  yield all([
    takeLatest('auth/userLogin', userLogin),
    takeLatest('auth/logout', userLogout),
    takeLatest('auth/generateUserName', generateUserName),
    takeLatest('auth/signUpWithUserName', signUpWithUserName),
    takeLatest('auth/signUpWithEmail', signUpWithEmail),
    takeLatest('auth/signUpWithPhon', signUpWithPhon),
    takeLatest('auth/sendOtp', sendOtp),
    takeLatest('auth/forgetPassword', forgetPassword),
    takeLatest('auth/confirmEmail', confirmEmail),
    takeLatest('auth/confirmUserName', confirmUserName),
    takeLatest('auth/confirmPhoneNo', confirmPhoneNo),
    takeLatest('auth/resetPassword', resetPassword),
    takeLatest('auth/imageUpload', imageUpload),
    takeLatest('auth/updateWithUserName', updateWithUserName),
    takeLatest('auth/userSocialLoginSignup', userSocialLoginSignup),
    takeLatest('auth/checkToken', checkToken),
    takeLatest('auth/sendCredentialToPhone', sendCredentialToPhone),
    takeLatest('auth/sendCredentialToMail', sendCredentialToMail),
    takeLatest('auth/userNameLimit', userNameLimit),
  ])
}

export default authSaga;