import { Route, Routes } from "react-router-dom";
import * as Containers from "../app/containers";
import * as Layouts from "../app/layouts"

const Router = () => {

    return (
        <>
            <Routes>
                <Route element={<Layouts.MainLayout />}>
                    <Route path="/" element={<Containers.Home />} />
                    <Route path="*" element={<Containers.Home />} />
                    <Route path="/resetPassword/:resetPasswordToken" element={<Containers.ResetPassword />} />
                </Route>

                <Route element={<Layouts.PrivateCheck auth={true} />}>
                    <Route path="/dashboard" element={<Containers.AccountDashboard />} />
                </Route>


            </Routes>
        </>
    );
}

export default Router;