import React, { useEffect, useState } from 'react'
import * as Images from "../../../utilities/images";
import SendModal from '../../containers/sendModal';
import GetCreadentials from './getCreadentials';
import CustomModal from './shared/CustomModal';
import { useTranslation } from 'react-i18next';

const ShowDetailsModal = (props) => {
  // console.log("props", props)
  const { t } = useTranslation();
  const [username, setUserName] = useState(props?.details?.username ? props?.details?.username : "");
  const [password, setPassword] = useState(props?.details?.password ? props?.details?.password : "");
  const [countryName, setCountryName] = useState("");
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
    type: "",
  });
  const [key, setKey] = useState(Math.random());


  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };


  const handleModalChange = (flag) => {
    if (flag == "credentialsModal") {
      setModalDetail({ show: true, flag: flag });
      setKey(Math.random());
    }
    if (flag == "sendModal") {
      setModalDetail({ show: true, flag: flag });
      setKey(Math.random());
    }
    if (flag == "credentialsEmailModal") {
      setModalDetail({ show: true, flag: flag });
      setKey(Math.random());
    }
    if (flag == "sendEmailModal") {
      setModalDetail({ show: true, flag: flag });
      setKey(Math.random());
    }
  }




  function downloadTextFile() {
    handleOnCloseModal();
    // Create a Blob with the text content
    const textContent = ` ${t('Login')} - ${props?.details?.username} \n ${t('Password')} - ${props?.details?.password} \n Url - https://betting.itechnolabs.tech/`;
    const blob = new Blob([textContent], { type: 'text/plain' });
    // Create a download link
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = `${props?.details?.username}.txt`; // Specify the filename here
    // Trigger the download
    downloadLink.click();
  }


  const dowanlodUsernameOrPassword = (flag) => {
    handleModalChange(flag)
  }

  useEffect(() => {
    fetch('https://geolocation-db.com/json/') // Replace with your server-side endpoint
      .then((response) => response.json())
      .then((data) => {
        //  setIpAddress(data);
        setCountryName(data.country_code.toLowerCase())
      })
      .catch((error) => console.error('Error fetching IP address:', error));

  }, []);


  return (
    <>
      <div className='emailModal mt-3'>
        <div className='sendModal text-center'>
          {/* <img src={Images.email3} alt="tel" className='mb-3' /> */}
          <h1 className='heading22 headingText'>{t("Hello")} {props?.details?.username} <br></br> {t("your_login_is")} {props?.details?.username} <br></br> {t("and your password is")}  {props?.details?.password}</h1>
          {/* <p className='subTxt10'>{t("OptionsForCredentials")}</p> */}
          <p className='subTxt10'> {t("How would you like to save your information?")}</p>
          <div className='col-md-12 mb-2'>
            <button className='credentialsBtn'
              onClick={() => downloadTextFile()}
              type="button"
            >{t("DownloadCredentialsAsFile")} <img src={Images.rightArrow} /></button>
          </div>
          <div className='col-md-12 mb-2'>
            <button className='credentialsBtn'
              onClick={() => { handleModalChange("credentialsModal") }}
              type="button"
            >{t("SendCredentialsToPhoneNumber")} <img src={Images.rightArrow} /></button>
          </div>
          <div className='col-md-12 mb-2'>
            <button className='credentialsBtn' type="button"
              onClick={() => { handleModalChange("credentialsEmailModal") }}
            >{t("SendCredentialsToEmailAddress")} <img src={Images.rightArrow} /> </button>
          </div>
          <div className='col-md-12'>
          </div>
        </div>
      </div>

      <CustomModal
        key={key}
        showModal={modalDetail.show}
        setShowModal={setModalDetail}
        title={modalDetail.title}
        type={modalDetail.type}
        className={modalDetail.flag === "signupModal" ? "signupModal" : ""}
        dialogClassName="modal-dialog-centered"
        backdrop="static"
        ids={modalDetail.flag === "credentialsModal" ? "credentialsModal" : modalDetail.flag === "sendModal" ? "credentialsModal" : modalDetail.flag === "credentialsEmailModal" ? "credentialsModal" : modalDetail.flag === "sendEmailModal" ? "credentialsModal" : ''}
        onCloseModal={() => handleOnCloseModal()}
        showCloseBtn={"show"}

        child={
          modalDetail.flag === "sendModal" ?
            <>
              <SendModal onCloseModal={() => handleOnCloseModal()} />
            </>
            : modalDetail.flag === "sendEmailModal" ?
              <>
                <SendModal email={true} onCloseModal={() => handleOnCloseModal()} />
              </>
              :
              modalDetail.flag === "credentialsEmailModal" ?
                <>
                  <GetCreadentials onCloseModal={() => handleOnCloseModal()} email={true} handleModalChange={(e) => handleModalChange(e)} userName={username} password={password} />
                </>
                : modalDetail.flag === "credentialsModal" ?
                  <>
                    <GetCreadentials onCloseModal={() => handleOnCloseModal()} handleModalChange={(e) => handleModalChange(e)} userName={username} password={password} countryName={countryName} />
                  </>

                  :
                  null
        }
      />
    </>
  )
}

export default ShowDetailsModal