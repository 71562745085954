import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    userInfo: {},
    userSignUp: {},
    generatedUserInfo: {},
    isLoggedIn: false,
    loading: false,
}

// Action creators are generated for each case reducer function
export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: ({
        userLogin: (state) => {
            state.loading = true
        },
        setUserLogin: (state, action) => {
            state.loading = false
            state.isLoggedIn = !action?.payload ? false : true
            state.userInfo = action.payload
        },

        signUpWithUserName: (state) => {
            state.loading = true
        },
        setSignUpWithUserName: (state, action) => {
            state.loading = false
            state.isLoggedIn = true
            state.userInfo = action.payload
        },
        updateWithUserName: (state) => {
            state.loading = true
        },
        setUpdateWithUserName: (state, action) => {
            state.loading = false
            state.isLoggedIn = true
            state.userInfo.user = action.payload
        },

        signUpWithEmail: (state) => {
            state.loading = true
        },
        setSignUpWithEmail: (state, action) => {
            state.loading = false
            state.isLoggedIn = true
            state.userInfo = action.payload
        },

        signUpWithPhon: (state) => {
            state.loading = true
        },
        setSignUpWithPhon: (state, action) => {
            state.loading = false
            state.isLoggedIn = true
            state.userInfo = action.payload
        },
        userSocialLoginSignup: (state) => {
            state.loading = true
        },
        setUserSocialLoginSignup: (state, action) => {
            console.log("action", action)
            state.loading = false
            state.isLoggedIn = true
            state.userInfo = action.payload
        },

        sendOtp: (state) => {
            state.loading = true
        },
        setSendOtp: (state, action) => {
            state.loading = false
        },


        generateUserName: (state) => {
            state.loading = true
        },
        setGenerateUserName: (state, action) => {
            state.loading = false
            state.generatedUserInfo = action.payload
        },

        userNameLimit: (state) => {
            state.loading = true
        },
        setUserNameLimit: (state, action) => {
            state.loading = false
        },

        resetPassword: (state) => {
            state.loading = true
        },
        setResetPassword: (state, action) => {
            state.loading = false
            state.isLoggedIn = true
            state.userInfo = action.payload
        },

        checkToken: (state) => {
            state.loading = true
        },
        setCheckToken: (state, action) => {
            state.loading = false
        },


        forgetPassword: (state) => {
            state.loading = true
        },
        setForgetPassword: (state, action) => {
            state.loading = false
            state.userInfo = {}
            state.userSignUp = {}
            state.isLoggedIn = false
        },

        confirmEmail: (state) => {
            state.loading = true
        },
        setConfirmEmail: (state, action) => {
            state.loading = false
        },
        confirmUserName: (state) => {
            state.loading = true
        },
        setConfirmUserName: (state, action) => {
            state.loading = false
        },
        confirmPhoneNo: (state) => {
            state.loading = true
        },
        setConfirmPhoneNo: (state, action) => {
            state.loading = false
        },
        imageUpload: (state) => {
            state.loading = true
        },
        setImageUpload: (state, action) => {
            state.loading = false
        },
        sendCredentialToPhone: (state) => {
            state.loading = true
        },
        setSendCredentialToPhone: (state, action) => {
            state.loading = false
        },
        sendCredentialToMail: (state) => {
            state.loading = true
        },
        setSendCredentialToMail: (state, action) => {
            state.loading = false
        },

        logout: (state) => {
            state.loading = true
        },
        setLogout: (state, action) => {
            state.loading = false
            state.userInfo = {}
            state.userSignUp = {}
            state.generatedUserInfo = {}
            state.isLoggedIn = false
        },
        onErrorStopLoad: (state) => {
            state.loading = false
        }
    }),
})

// Action creators are generated for each case reducer function
export const { userLogin, setUserLogin,
    generateUserName,
    setGenerateUserName,
    userNameLimit,
    setUserNameLimit,
    signUpWithUserName,
    setSignUpWithUserName,
    userSocialLoginSignup,
    setUserSocialLoginSignup,
    signUpWithEmail,
    setSignUpWithEmail,
    signUpWithPhon,
    setSignUpWithPhon,
    sendOtp,
    setSendOtp,
    forgetPassword,
    setForgetPassword,
    confirmEmail,
    setConfirmEmail,
    confirmUserName,
    setConfirmUserName,
    confirmPhoneNo,
    setConfirmPhoneNo,
    resetPassword,
    setResetPassword,
    checkToken,
    setCheckToken,
    imageUpload,
    setImageUpload,
    updateWithUserName,
    sendCredentialToMail,
    sendCredentialToPhone,
    setSendCredentialToPhone,
    setUpdateWithUserName,
    setSendCredentialToMail,
    logout,
    setLogout,
    onErrorStopLoad
} = authSlice.actions

export default authSlice.reducer