import React from 'react'
import { Image } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import * as Images from "../../../utilities/images";
import { useTranslation } from 'react-i18next';


const TokenExpireModal = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClose = () => {
    navigate("/");
    props.onCloseModal();

  }

  return (
    <>
      <div className='emailModal mt-3'>
        <div className='sendModal text-center'>
          <img src={Images.email3} alt="tel" className='mb-3' />
          <h1 className='heading22'>{t("TokenExpire")}</h1>
          <p className='subTxtPurpl12'> {t("ResetPasswordInstructions")}</p>
          <div className='col-md-12'>
            <button className='loginBtnCommon  w-100' type="button" onClick={() => handleClose()}>{t("Okay")}</button>
          </div>
        </div>
      </div>
    </>
  )
}

export default TokenExpireModal