import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import MainFooter from '../components/common/mainFooter';
import MainNav from '../components/common/mainNav';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useEffect } from 'react';

const MainLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();

  /*   useEffect(() => {
      if (location.pathname.split("/")[1] != "resetPassword" && location.pathname.split("/")[1] != "dashboard") {
        navigate("/")
      }
    }, [])
   */
  return (
    <GoogleOAuthProvider clientId="987771494988-n6qhh240uaoqbc6ereljq08cum097lna.apps.googleusercontent.com">
      {location.pathname.split("/")[1] != "resetPassword" && <MainNav />}
      <main className='main'>
        <Outlet />
      </main>
      <MainFooter />
    </GoogleOAuthProvider>
  );
};

export default MainLayout;