import React from "react"
import { Link, useNavigate } from "react-router-dom";
import * as Images from "../../../utilities/images";
import { Nav } from "react-bootstrap";
const MainFooter = (props) => {
    return (
        <>
            {/* <section className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-6 col-sm-4 col-md-3">
                            <h4 className="heading_">Company</h4>
                            <ul className="footerMenus">
                                <li className="footerItems">
                                    <Nav.Link className="footerLinks" href="/aboutUs#press&news"
                                    >Press & News</Nav.Link>
                                </li>
                                <li className="footerItems">
                                    <Link to='/privacyPolicy' className="footerLinks">Privacy Policy</Link>
                                </li>
                                <li className="footerItems">
                                    <Link to='/termsCondition' className="footerLinks">Terms of Service</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-6 col-sm-4 col-md-3">
                            <h4 className="heading_">Community</h4>
                            <ul className="footerMenus">
                                <li className="footerItems">
                                    <Link to='/chooserolesfor=signup' className="footerLinks">Get Started</Link>
                                </li>
                                <li className="footerItems">
                                    <Link to='/membership' className="footerLinks">Membership</Link>
                                </li>
                                <li className="footerItems">
                                    <Link to='/resources' className="footerLinks">Resources</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-6 col-sm-4 col-md-3">
                            <h4 className="heading_">Support</h4>
                            <ul className="footerMenus">
                                <li className="footerItems">
                                    <Link to='/frequentQuestion' className="footerLinks">FAQ's</Link>
                                </li>
                                <li className="footerItems">
                                    <Link to='/aboutUs' className="footerLinks">About Us</Link>
                                </li>
                                <li className="footerItems" >
                                    <Link to='/contactUs' className="footerLinks">Contact Us</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <section className="footerBottom">
                        <div className="row align-items-center">
                            <div className="col-6 col-md-4">
                                <ul className="socialMenus">
                                    <li className="socialItems">
                                        <Link to='' className="socialLinks">
                                            <img src={Images.twitterIcon} alt="Twitter" />
                                        </Link>
                                    </li>
                                    <li className="socialItems">
                                        <Link to='' className="socialLinks">
                                            <img src={Images.facebookIcon} alt="Facebook" />
                                        </Link>
                                    </li>
                                    <li className="socialItems">
                                        <Link to='' className="socialLinks">
                                            <img src={Images.instaIcon} alt="Insta" />
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-6 col-md-8 text-end">
                                <p className="subtextInner">
                                    © 2023 GetOpps
                                </p>
                            </div>
                        </div>
                    </section>
                </div>
            </section> */}
        </>
    )

}

export default MainFooter;