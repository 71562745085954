const ApiPath = {
    AuthApiPath: {
        LOGIN_USER: '/api/v1/users/login',
        SIGNUP_WITH_USERNAME: '/api/v1/users/signupwith/username',
        SIGNUP_WITH_EMAIL: '/api/v1/users/signupwith/email',
        SIGNUP_WITH_PHONE: '/api/v1/users/signupwith/phone',
        GENERATE_USER: '/api/v1/users/generate-credentials',
        USERNAME_LIMIT: '/api/v1/users/get-validation',
        SEND_OTP: '/api/v1/users/send-otp',
        FORGET_PASSWORD: '/api/v1/users/forgot-password',
        LOGOUT_USER: '/api/v1/users/logout',
        CONFIRM_EMAIL: '/api/v1/users/confirm/email',
        CONFIRM_USERNAME: '/api/v1/users/confirm/username',
        CONFIRM_PHONE: '/api/v1/users/confirm/phone',
        RESET_PASSWORD: '/api/v1/users/reset-password',
        UPDATE_WITH_USERNAME: '/api/v1/users/update',
        IMAGE_UPLOAD: '/api/v1/users/upload',
        USER_SOCIAL_LOGIN_SIGNUP: '/api/v1/users/social-login',
        CHECK_TOKEN: "/api/v1/users/token-check",
        SEND_CERDENTIAL_MALE: "/api/v1/users/send-credentials",
        SEND_CERDENTIAL_PHONE: " ",
    },

   
}

export default ApiPath;
