import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';

const language = localStorage.getItem('language');
i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: language ? language : 'en', // Default language
    fallbackLng: 'en', // Fallback language
    debug: true, // Enable debugging
    interpolation: {
      escapeValue: false, // React handles escaping
    },
  });




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <I18nextProvider i18n={i18n}>
        <App/>
    </I18nextProvider>
    
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
