import React, { useEffect, useState } from 'react';
import * as Images from "../../../utilities/images";
import { Link, useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { sendOtp, setGenerateUserName, userLogin, userSocialLoginSignup } from '../../../redux/slices/auth';
import { useGoogleLogin } from '@react-oauth/google';
import { useTranslation } from 'react-i18next';
import TelegramLoginButton from 'react-telegram-login';
import { useAuthSelector } from '../../../redux/selector/auth';
import OTPModal from './OTPModal';

const LoginModal = (props) => {
    const dispatch = useDispatch();
    const authSelector = useAuthSelector();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState(1);
    const [userName, setUserName] = useState("");
    const [userPassword, setUserPassword] = useState("");
    const [email, setEmail] = useState("");
    const [emailPassword, setEmailPassword] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [phoneCode, SetPhoneCode] = useState("");
    const [logInError, setLogInError] = useState("");
    const [key, setKey] = useState(Math.random());
    const [timeRemaining, setTimeRemaining] = useState(); // 10 minutes in seconds
    const [isActive, setIsActive] = useState(false);
    const [emailLoading, setEmailLoading] = useState(false);
    const [phoneLoading, setPhoneLoading] = useState(false);
    const [usernameLoading, setUsernameLoading] = useState(false);
    const [isOtpModal, setIsOtpModal] = useState(false);
    const [otpModalData, setOtpModalData] = useState({});
    const [phoneVal, setphoneVal] = useState("");

    //Create for change tabs.
    const handleTabClick = async (tabNumber) => {
        setActiveTab(tabNumber);
        setLogInError("");
        setIsActive(false)
        setTimeRemaining()
    };


    //Create for login using email.
    const handleEmailLogin = () => {
        if (!email) {
            toast.error(t("Please enter email"))
            // setLogInError({ email: t("Please enter email") });
            return;
        }
        else if (!emailPassword) {
            toast.error(t("Please enter password"))
            // setLogInError({ emailPassword: t("Please enter password") });
            return;
        }
        let params = {
            email: email.trim(),
            password: emailPassword,
            login_with: "email"
        }
        setEmailLoading(true)
        dispatch(userLogin({
            ...params, cb(res) {
                setEmailLoading(false)
                if (res?.status == 400) {
                    toast.error(t(res.data.message))
                    // setLogInError({ loginError: t(res.data.message) });
                }
                if (res?.status == 429) {
                    toast.error(t(res.data.message))
                    setTimeRemaining(res.data.data.lockout_period)
                    // setLogInError({ loginError: t(res.data.message) });
                    setIsActive(true)
                }
                if (res?.status == 200) {
                    if (res.data.data.user.username !== null) {
                        dispatch(setGenerateUserName({ ...authSelector?.generatedUserInfo, saved: true }))
                    }
                    navigate("/dashboard")
                    setLogInError("")
                }

            }
        }))
    }

    //Create for login using username.
    const handleUserLogin = () => {
        if (!userName) {
            toast.error(t("Please enter userName"))
            // setLogInError({ email: t("Please enter user name") });
            return;
        }
        else if (!userPassword) {
            toast.error(t("Please enter password"))
            // setLogInError({ emailPassword: t("Please enter password") });
            return;
        }
        let params = {
            username: userName,
            password: userPassword,
            login_with: "username"
        }
        setUsernameLoading(true)
        dispatch(userLogin({
            ...params, cb(res) {
                setUsernameLoading(false)
                if (res?.status == 400) {
                    toast.error(t(res.data.message))
                    // setLogInError({ loginError: t(res.data.message) });
                }
                if (res?.status == 429) {
                    toast.error(t(res.data.message))
                    setTimeRemaining(res.data.data.lockout_period)
                    // setLogInError({ loginError: t(res.data.message) });
                    setIsActive(true)
                }
                if (res?.status == 200) {
                    if (res.data.data.user.username !== null) {
                        dispatch(setGenerateUserName({ ...authSelector?.generatedUserInfo, saved: true }))
                    }
                    navigate("/dashboard")
                    setLogInError("")
                }
            }
        }))
    }

    //Create for send otp to phone number.
    const handlePhoneClick = () => {
        if (!phoneNo) {
            toast.error(t("Please enter phone number"))
            // setLogInError({ email: t("Please enter phone number") });
            return;
        }
        let params = {
            phone_code: phoneCode.includes('+') ? phoneCode : '+' + phoneCode,
            phone: phoneNo,
            type: "login",
        }
        setPhoneLoading(true)
        dispatch(sendOtp({
            ...params, cb(res) {
                setPhoneLoading(false)
                if (res?.status == 400) {
                    toast.error(t(res.data.message))
                    // setLogInError({ loginError: t(res.data.message) });

                }
                if (res?.status == 200) {
                    setOtpModalData({
                        phone_code: phoneCode.includes('+') ? phoneCode : '+' + phoneCode,
                        phone: phoneNo,
                        type: 'login',
                    })
                    setIsOtpModal(true)
                    setLogInError("")
                }

            }
        }))
    }

    //Create for input phon number or phone code.
    const onChangePhoneNumber = (value, data, event, formattedValue) => {
        let phoneCode = data.dialCode;
        let phoneNumber = value.slice(data.dialCode.length);
        setphoneVal(value)
        setPhoneNo(phoneNumber);
        SetPhoneCode(phoneCode);
        setLogInError("");
    }

    //create for formet the time 
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    //Create for login with google.
    const loginWithGoogle = useGoogleLogin({
        onSuccess: async (codeResponse) => {
            if (!codeResponse) {
                toast.error(t("User details not found"))
                return;
            }
            let params = {
                token: codeResponse.access_token,
                provider: "google",
            }
            dispatch(userSocialLoginSignup({
                ...params, cb(res) {
                    if (res?.status == 400) {
                        toast.error(t(res.data.message))
                        // setLogInError({ error: t(res.data.message) });
                    }
                    if (res?.status == 200) {
                        if (res.data.data.user.username !== null) {
                            dispatch(setGenerateUserName({ ...authSelector?.generatedUserInfo, saved: true }))
                        }
                        navigate("/dashboard")
                        setLogInError("");
                    }
                }
            }))
        },
        onError: (error) => console.log('Login Failed:', error)
    })


    const handleTelegramResponse = (response) => {
        // Handle the Telegram response here, which may include user data or an error.
        console.log("Telegram Auth Response", response);
        if (!response) {
            toast.error(t("User details not found"))
            return;
        }
        let params = {
            token: response.hash,
            provider: "telegram",
            username: response.id
        }
        dispatch(userSocialLoginSignup({
            ...params, cb(res) {
                if (res?.status == 400) {
                    toast.error(t(res.data.message));
                    // setLogInError({ error: t(res.data.message) });
                }
                if (res?.status == 200) {
                    if (res.data.data.user.username !== null) {
                        dispatch(setGenerateUserName({ ...authSelector?.generatedUserInfo, saved: true }))
                    }
                    navigate("/dashboard")
                    setLogInError("");
                }
            }
        }))
    };


    useEffect(() => {
        let timerId;
        if (isActive && timeRemaining > 0) {
            timerId = setInterval(() => {
                setTimeRemaining((prevTime) => prevTime - 1);
            }, 1000); // 1000 milliseconds = 1 second
        } else if (timeRemaining === 0) {
            // Timer has reached 0, you can add logic here for what to do when the timer expires.
            setIsActive(false);
            setLogInError("")
            clearInterval(timerId);
        }

        // Clean up the timer when the component unmounts
        return () => clearInterval(timerId);
    }, [isActive, timeRemaining]);



    return (
        <>
            {isOtpModal ? <OTPModal setIsOtpModal={setIsOtpModal} data={otpModalData} /> :
                <div className="loginModal">
                    <h2 className="heading22">{t('login')}</h2>
                    <div className="tab-buttons">
                        <button
                            className={activeTab === 1 ? 'tabBtn active' : 'tabBtn'}
                            onClick={() => handleTabClick(1)}
                        >
                            <img src={Images.emailIcon} alt="icon" className='tabIcons' />
                            <span className='heading'>{t('With Email')}</span>
                            <span className='mobileheading d-none'>{t(`with`)}<br></br>{t(`Email`)}</span>
                        </button>
                        <button
                            className={activeTab === 2 ? 'tabBtn active' : 'tabBtn'}
                            onClick={() => handleTabClick(2)}
                        >
                            <img src={Images.Whatsapp} alt="icon" className='tabIcons' />
                            <span className='heading'>{t('With Whatsapp')} </span>
                            <span className='mobileheading d-none'>{t(`with`)}<br></br>{t(`Whatsapp`)}</span>
                        </button>
                        <button
                            className={activeTab === 3 ? 'tabBtn active' : 'tabBtn'}
                            onClick={() => handleTabClick(3)}
                        >
                            <img src={Images.socialIcon} alt="icon" className='tabIcons' />
                            <span className='heading'>{t('With Social Media')}</span>
                            <span className='mobileheading d-none'>{t(`with`)}<br></br>{t(`socialMedia`)}</span>
                        </button>
                        <button
                            className={activeTab === 4 ? 'tabBtn active' : 'tabBtn'}
                            onClick={() => handleTabClick(4)}
                        >
                            <img src={Images.userIcon} alt="icon" className='tabIcons' />
                            <span className='heading'> {t('With Username')}</span>
                            <span className='mobileheading d-none'>{t(`with`)}<br></br>{t(`Username`)}</span>
                        </button>
                    </div>
                    <div className='hr'></div>

                    <div className="tab-content">
                        {activeTab === 1 &&
                            <div>
                                <form className="customForm">
                                    <div className='row'>
                                        <div className='col-md-6 mb-4'>
                                            <div className="form-group">
                                                <label htmlFor="username" className="form-label">{t("EmailAddress")}</label>
                                                <input type="email" id="username" className="form-input" placeholder={t("EnterEmail")} value={email} onChange={(e) => { setEmail(e.target.value); setLogInError("") }} />
                                                {isActive ?
                                                    <span className='sendTime'>{formatTime(timeRemaining)}</span> : ""}
                                            </div>
                                            {logInError ?
                                                <span className={logInError?.email ? 'validationMsg' : "d-none"}>{logInError?.email ? <>{t(`${logInError?.email}`)}</> : ""}</span>
                                                : ""}
                                            {logInError ?
                                                <span className={logInError?.loginError ? 'validationMsg' : "d-none"}>{logInError?.loginError}</span>
                                                : ""}
                                        </div>
                                        <div className='col-md-6 mb-4'>
                                            <div className="form-group">
                                                <label htmlFor="password" className="form-label">{t("Password")}</label>
                                                <input type="text" id="password" className="form-input" placeholder={t("EnterPassword")} value={emailPassword} onChange={(e) => { setEmailPassword(e.target.value); setLogInError("") }} />
                                                {logInError ?
                                                    <span className={logInError?.emailPassword ? 'validationMsg' : "d-none"}>{logInError?.emailPassword ? <>{t(`${logInError?.emailPassword}`)}</> : ""}</span>
                                                    : ""}<br></br>
                                                <span className='text-end d-block'><Link href="#" className='forgotLink'
                                                    onClick={() => {
                                                        props?.handleTypeChange("forgetModal")

                                                    }}
                                                >{t("ForgotPassword")}</Link></span>
                                            </div>
                                        </div>
                                        <div className='col-md-12 text-center mt-2'>
                                            <button className='loginBtnCommon btnYellow' type="button" onClick={() => handleEmailLogin()} disabled={isActive}>
                                                {emailLoading ? <><span className="spinner-border spinner-border-sm"></span></>
                                                    : <> {t("Login")} </>}
                                            </button>
                                        </div>
                                        <div className='col-md-12 text-center mt-3'>
                                            <p className='loginTxt'>{t("If you don't have an account?")} <Link href="#"
                                                onClick={() => { props?.handleTypeChange("signupModal") }}
                                                className='loginLink'>{t("signup")}</Link></p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        }
                        {activeTab === 2 &&
                            <div>
                                <form className="customForm">
                                    <div className='row'>
                                        <div className='col-md-12 mb-4'>
                                            <div className="form-group">
                                                <label htmlFor="username" className="form-label">{t("Phone Number")}</label>
                                                <PhoneInput
                                                    className="form-input"
                                                    country={props.countryName || 'us'}
                                                    placeholder={t("EnterPhoneNumber")}
                                                    enableSearch={true}
                                                    value={phoneVal}
                                                    onChange={(value, data, event, formattedValue) => { onChangePhoneNumber(value, data, event, formattedValue) }}
                                                />
                                            </div>
                                            {logInError ?
                                                <span className={logInError?.email || logInError?.loginError ? 'validationMsg' : "d-none"}>{logInError?.email ? logInError?.email ? <>{t(`${logInError?.email}`)}</> : "" : logInError?.loginError}</span>
                                                : ""}
                                        </div>
                                        {isActive ? <span className='sendTime'>{formatTime(timeRemaining)}</span> : ""}


                                        <div className='col-md-12 text-center mt-2'>
                                            <button className='loginBtnCommon btnYellow' type='button' disabled={isActive} onClick={() => handlePhoneClick()}
                                            > {phoneLoading ? <><span className="spinner-border spinner-border-sm"></span></>
                                                : <>{t("SendOTP")}</>}</button>

                                        </div>

                                        <div className='col-md-12 text-center mt-3'>
                                            <p className='loginTxt'>{t("If you don't have an account?")}<Link href="#"
                                                onClick={() => { props?.handleTypeChange("signupModal") }}
                                                className='loginLink'>{t("signup")}</Link></p>

                                        </div>
                                    </div>
                                </form>
                            </div>
                        }
                        {activeTab === 3 &&
                            <div>
                                <form className="customForm">
                                    <div className='row'>
                                        <div className='col-md-6 text-center'>
                                            <button className='googleBtn' type='button'
                                                onClick={() => { loginWithGoogle(); setLogInError(""); }}
                                            ><img src={Images.googleIcon} alt="google" className='me-2' />{t("LoginWithGoogle")}</button>
                                            {logInError ?
                                                <span className={logInError?.error ? 'validationMsg' : "d-none"}>{logInError?.error}</span>
                                                : ""}
                                        </div>
                                        <div className='col-md-6 text-center telegrambtn'>
                                            <button className='googleBtn facebook' type='button'
                                            ><img src={Images.TelegramIcon} alt="facebook" className='me-2' />{t("LoginWithTelegram")}</button>
                                            <div className='innertelegram_button'>
                                                <TelegramLoginButton
                                                    botName="itechbetting_bot"
                                                    dataOnauth={handleTelegramResponse}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-12 text-center mt-4'>
                                            <p className='loginTxt'>{t("If you don't have an account?")}<Link href="#"
                                                onClick={() => { props?.handleTypeChange("signupModal") }}
                                                className='loginLink'>{t("signup")}</Link></p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        }
                        {activeTab === 4 &&
                            <div>
                                <form className="customForm">
                                    <div className='row'>
                                        <div className='col-md-6 mb-4'>
                                            <div className="form-group">
                                                <label htmlFor="username" className="form-label ">{t("Username")}</label>
                                                <input type="text" id="username" className="form-input labelWithCopy" placeholder={t("EnterUsername")} value={userName} onChange={(e) => { setUserName(e.target.value); setLogInError("") }} />
                                            </div>
                                            {isActive ?
                                                <span className='sendTime'>{formatTime(timeRemaining)}</span> : ""}

                                            {logInError ?
                                                <span className={logInError?.email ? 'validationMsg' : "d-none"}>{logInError?.email ? <>{t(`${logInError?.email}`)}</> : ""}</span>
                                                : ""}
                                            {logInError ?
                                                <span className={logInError?.loginError ? 'validationMsg' : "d-none"}>{logInError?.loginError}</span>
                                                : ""}

                                        </div>
                                        <div className='col-md-6 mb-4'>
                                            <div className="form-group">
                                                <label htmlFor="password" className="form-label">{t("Password")}</label>
                                                <input type="text" id="password" className="form-input labelWithCopy" placeholder={t("EnterPassword")} value={userPassword} onChange={(e) => { setUserPassword(e.target.value); setLogInError("") }} />
                                            </div>
                                            {logInError ?
                                                <span className={logInError?.emailPassword ? 'validationMsg' : "d-none"}>{logInError?.emailPassword ? <>{t(`${logInError?.emailPassword}`)}</> : ""}</span>
                                                : ""}
                                        </div>

                                        <div className='col-md-12 text-center mt-4'>
                                            <button className='loginBtnCommon btnYellow ' type="button" disabled={isActive} onClick={() => handleUserLogin()}>
                                                {usernameLoading ? <><span className="spinner-border spinner-border-sm"></span></>
                                                    : <> {t("Login")} </>}
                                            </button>
                                        </div>
                                        <div className='col-md-12 text-center mt-3'>
                                            <p className='loginTxt'>{t("If you don't have an account?")} <Link href="#"
                                                onClick={() => { props?.handleTypeChange("signupModal") }}
                                                className='loginLink'>{t("signup")}</Link></p>

                                        </div>
                                    </div>
                                </form>
                            </div>
                        }
                    </div>
                </div>}
        </>
    );
};

export default LoginModal;