import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { Signup } from '../../containers';
import { signUpWithUserName, sendCredentialToMail, sendCredentialToPhone } from '../../../redux/slices/auth';
import { toast } from 'react-toastify';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import { useTranslation } from 'react-i18next';


const GetCreadentials = (props) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [phoneCode, SetPhoneCode] = useState("");
  const [signUpError, setSignUpError] = useState("");
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  //Create for send username or password to email and phone  
  const handlSend = () => {
    let params = {}
    if (props?.email) {
      if (!email) {
        toast.error(t("Please enter email"))
        // setSignUpError(t("Please enter email"));
        return;
      }
      params = {
        password: props?.password,
        email: email,
        type: "email"
      }
      setLoading(true)
      dispatch(sendCredentialToMail({
        ...params, cb(res) {
          setLoading(false)
          if (res?.status == 400) {
            toast.error(t(res.data.message))
            // setError(t(res.data.message))
          }
          if (res?.status == 200) {
            props.handleModalChange(props?.email ? "sendEmailModal" : "sendModal")
          }
        }
      }))
    }
    else {
      if (!phoneNo) {
        toast.error(t("Please enter phone number"))
        // setSignUpError(t("Please enter phone number"));
        return;
      }
      params = {
        password: props?.password,
        phone: phoneNo,
        type: "phone",
        phone_code: phoneCode.includes('+') ? phoneCode : '+' + phoneCode
      }
      setLoading(true)
      dispatch(sendCredentialToMail({
        ...params, cb(res) {
          setLoading(false)
          if (res?.status == 400) {
            toast.error(t(res.data.message))
            // setError(t(res.data.message))
          }
          if (res?.status == 200) {
            props.handleModalChange(props?.email ? "sendEmailModal" : "sendModal")
          }

        }
      }))
    }
  }

  //Create for input phon number or phone code.
  const onChangePhoneNumber = (value, data, event, formattedValue) => {
    let phoneCode = data.dialCode;
    let phoneNumber = value.slice(data.dialCode.length);
    setPhoneNo(phoneNumber);
    SetPhoneCode(phoneCode);
  }


  return (
    <>
      <div className='getCreden'>
        <h1 className='heading22'>{t("GetCredentials")}</h1>
        {
          props?.email ?
            <p className='subTxtPurpl12'>{t("EnterEmailForCredentials")}</p>
            :
            <p className='subTxtPurpl12'>{t("EnterPhoneForCredentials")}</p>
        }

        <div className='hr'></div>
        <form className="customForm">
          <div className='row'>
            <div className='col-md-12 mb-4'>
              {
                props?.email ?
                  <div className="form-group">
                    <label htmlFor="username" className="form-label">{t("Email")}</label>
                    <input type="email" id="email" className="form-input" placeholder={t("EnterEmail")} onChange={(e) => { setEmail(e.target.value) }} maxLength={25} value={email} />
                    {signUpError ?
                      <span className={signUpError ? 'validationMsg' : "d-none"}>{signUpError ? <>{t(`${signUpError}`)}</> : ""}</span>
                      : ""}
                    {error ?
                      <span className={error ? 'validationMsg' : "d-none"}>{error}</span>
                      : ""}
                  </div>
                  :
                  <div className="form-group">
                    <label htmlFor="username" className="form-label">{t("Phone Number")}</label>
                    <PhoneInput
                      className="form-input"
                      country={props.countryName}
                      enableSearch={true}
                      placeholder={t("EnterPhoneNumber")}
                      onChange={(value, data, event, formattedValue) => { onChangePhoneNumber(value, data, event, formattedValue) }}
                    />
                    {/* <input type="number" id="username" className="form-input" placeholder='phone number' onChange={(e) => handleChange(e ,"phonNumber")} maxLength={25} value={phonNumber} /> */}
                    {signUpError ?
                      <span className={signUpError ? 'validationMsg' : "d-none"}>{signUpError ? <>{t(`${signUpError}`)}</> : ""}</span>
                      : ""}
                    {error ?
                      <span className={error ? 'validationMsg' : "d-none"}>{error}</span>
                      : ""}
                  </div>
              }

            </div>
            <div className='col-md-12'>
              {/* <button className='loginBtnCommon w-100' type="button" onClick={() => props.handleModalChange(props?.email?"sendEmailModal":"sendModal")}>Send</button> */}
              <button className='loginBtnCommon w-100' type="button" onClick={() => handlSend()}>
                {loading ? <><span className="spinner-border spinner-border-sm"></span></>
                  : <> {t("Send")} </>}
              </button>

            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default GetCreadentials